import { Route, Routes } from "react-router-dom";
import ResumeScreeningPage from "../pages/HR/ResumeScreening/ResumeScreeningPage";
import ResumesScreeningPage from "../pages/HR/ResumeScreening/ResumesScreeningPage";

export default function ResumeScreeningRoutes(){
    return (
        <Routes>
            <Route index path="" element={<ResumesScreeningPage />} />
            <Route path=":id" element={<ResumeScreeningPage />} />
        </Routes>
    )
}