import { Helmet } from 'react-helmet-async';
import HeroSection from "../components/Solution/HeroSection";
import FeatureCardsSection from "../components/Landing/FeatureCardsSection";
import RecruitmentChallenges from "../components/Solution/RecruitmentChallenges";

export default function SolutionPage(){

    return (
        <div className="w-full overflow-hidden">
            <Helmet>
                <title>Solutions | Gen AI Interviewer </title>
                <meta name="description" content="Athmick AI provides AI-driven solutions for key recruitment challenges, including automated JD creation, resume-less hiring, bias-free evaluations, and rapid candidate selection." />
                <meta name="keywords" content="AI recruitment solutions,  resume-less hiring, resume shortlisting, resume screening, bias-free evaluations, interview automation, candidate selection, recruitment challenges, talent acquisition, AI-driven hiring, recruitment use cases." />
            </Helmet>
            <HeroSection />
            <RecruitmentChallenges />
            <FeatureCardsSection />
        </div>
    )
}