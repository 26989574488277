import { useState } from "react";
import { BounceLoader } from 'react-spinners';
import { MdOutlineClear } from "react-icons/md";
import { InputTextarea } from 'primereact/inputtextarea';
import SessionService from "../../services/session.service";
import FeedbackStatusDropdown from "./FeedbackStatusDropdown";
import { useToast } from "../../contextProviders/ToastContext";

export default function InterviewFeedbackPopup(props){
    const {showSuccessMessage} = useToast();
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [interviewStatus, setInterviewStatus] = useState(null);

    const submitFeedback = async () => {
        try {
            setLoading(true);
            const requestData = {};
            if(feedback) requestData['feedback'] = feedback;
            if(interviewStatus) requestData['status'] = interviewStatus;

            const response = await SessionService.submitInterviewFeedback(props.sessionId, requestData);
            setLoading(false);
            if(response.status === 200){
                showSuccessMessage({
                    life: 4000,
                    summary: 'Feedback Recorded',
                    detail: 'Your feedback has been successfully submitted.'
                })
                props.updateReport(); 
                props.hide();
            }
    
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <div className="h-screen w-screen bg-transparent z-40 absolute top-0 left-0 flex justify-center items-center">
            <div className="bg-white p-5 shadow-md rounded-md drop-shadow-md flex flex-col gap-5 w-1/2">
                <div className="flex justify-between items-center">
                    <h1 className="font-semibold text-primary text-xl">Interview Report Feedback</h1>
                    <MdOutlineClear 
                        size={20}
                        onClick={props.hide}
                        className="cursor-pointer"
                    />
                </div>
                <div className="flex flex-col gap-3">
                    <div className="flex items-center justify-between">
                        <h3 className="text-darkGray text-sm">Candidate Selection from recruiter / hiring manager</h3>
                        <FeedbackStatusDropdown
                            interviewStatus={interviewStatus}
                            setInterviewStatus={setInterviewStatus}
                        />
                    </div>
                    <div className="flex flex-col items-start gap-2">
                        <h3 className="text-darkGray text-sm">Feedback *</h3>
                        <InputTextarea
                            rows={10} 
                            value={feedback}
                            placeholder="Provide feedback on candidate selection status in the next round"
                            onChange={(e) => setFeedback(e.target.value)}
                            className="p-2 w-full border-[.3px] border-gray"  
                        />
                    </div>
                </div>
                <button
                    onClick={submitFeedback}
                    disabled={interviewStatus === null || (!feedback || feedback?.trim()?.length === 0)}
                    className={`bg-primary w-40 text-white h-10 flex justify-center items-center rounded-md m-auto ${interviewStatus === null || (!feedback || feedback?.trim()?.length === 0) ? 'opacity-40': 'opacity-100'}`}>
                    {loading ? <BounceLoader className="m-auto" size={26} color="#ffffff" /> : <span className="font-semibold text-xs">Submit Status</span>}
                </button>
            </div>
        </div>
    )
}