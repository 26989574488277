import React from 'react';
import useInView from '../../utils/animations/useInView';
import resumeicon from './../../assets/icons/resume-icon.svg';
import interviewicon from './../../assets/icons/interview-icon.svg';
import scheduleicon from './../../assets/icons/schedule-icon.svg';
import engagementicon from './../../assets/icons/engagement-icon.svg';
import evaluationicon from './../../assets/icons/evaluation-icon.svg';
import impersonationicon from './../../assets/icons/impersonation-icon.svg';
import AnimatedText from '../Common/AnimatedText';

const FeatureCard = ({ icon, title }) => {
  const [ref, isInView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`aspect-square bg-white rounded-lg shadow-md p-4 flex flex-col justify-center items-center gap-5 w-[80%] md:w-[25%] transition-all duration-500 ease-out ${
        isInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
    <div className="mb-4 flex items-center justify-center">
      <img src={icon} alt={title} className="md:w-28 md:h-28" />
    </div>
    <h3 className="text-center text-base font-normal text-darkGray">{title}</h3>
  </div>
  )
};

const TalentInnovatorPage = () => {
  const features = [
    { icon: resumeicon, title: 'JD-less/Resume-less hiring' },
    { icon: interviewicon, title: 'Automated In-depth interviews' },
    { icon: scheduleicon, title: 'Eliminate scheduling' },
    { icon: evaluationicon, title: 'Comprehensive evaluation' },
    { icon: engagementicon, title: 'Candidate engagement from entry to exit' },
    { icon: impersonationicon, title: 'Impersonation avoidance' },
  ];
  
  return (
    <div className='bg-gradient-to-b from-white to-[#F6F7F9] w-full'>
      <div 
        className="container mx-auto px-4 xl:w-[60%] sm:w-[90%] py-10 md:py-20 relative gap-20 flex flex-col justify-center items-center">
          <div className='flex flex-col gap-2 md:w-2/3'>
            <AnimatedText className="text-3xl md:text-4xl font-bold text-primary text-center">
              Transform into a <span className='text-l_g'>talent innovator</span> with our unique features
            </AnimatedText>
          </div>
          <div className="flex flex-wrap gap-6 md:gap-12 justify-center">
            {features.map((feature, index) => (
              <FeatureCard key={index} icon={feature.icon} title={feature.title} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default TalentInnovatorPage;