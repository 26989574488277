import React, { useState, useEffect } from 'react';
import { useMediaContext } from '../../contextProviders/InterviewMediaContext';
import { useSessionContext } from '../../contextProviders/SessionContext';

function VoiceVisualizer({bgColor= '#ffffff', isSpeaking = false, isBotSpeaking = false }) {
  const {audioStream } = useMediaContext();
  const [volume, setVolume] = useState([0, 0, 0, 0, 0]);
  const { sessionInfo } = useSessionContext();

  useEffect(() => {
    function init(){
      try {
        let audioContext, analyser, source;

        audioContext = new AudioContext();
        analyser = audioContext.createAnalyser();
        source = audioContext.createMediaStreamSource(audioStream);
        source.connect(analyser);

        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength); // Now defined here

        const visualizeAudio = () => {
            analyser.getByteFrequencyData(dataArray);

            const lowerHalfArray = dataArray.slice(0, dataArray.length / 2);
            const chunkSize = Math.floor(lowerHalfArray.length / 5);

            const newVolumeLevels = [
              lowerHalfArray.slice(0, chunkSize).reduce((a, b) => a + b, 0) / chunkSize,
              lowerHalfArray.slice(chunkSize, chunkSize * 2).reduce((a, b) => a + b, 0) / chunkSize,
              lowerHalfArray.slice(chunkSize * 2, chunkSize * 3).reduce((a, b) => a + b, 0) / chunkSize,
              lowerHalfArray.slice(chunkSize * 3, chunkSize * 4).reduce((a, b) => a + b, 0) / chunkSize,
              lowerHalfArray.slice(chunkSize * 4).reduce((a, b) => a + b, 0) / chunkSize,
            ];
            setVolume(newVolumeLevels);

            requestAnimationFrame(visualizeAudio);
        };

        visualizeAudio();

        return () => {
          if (audioContext) {
            audioContext.close();
          }
        };
      } catch (error) {}
    }

    init();
  }, []); 

  // if(volume[0] === 0 && !isSpeaking && !isBotSpeaking){
  //   return (
  //     <div className="flex gap-1 justify-center items-center bg-blue h-10 px-3 text-white font-medium rounded-full">
  //       <h3 className='text-sm'>{sessionInfo['candidate_name']}</h3>
  //     </div>
  //   );
  // }

  return (
    <div className="flex gap-1 justify-center items-center bg-blue h-10 w-32 rounded-full">
      {volume.map((vol, index) => (
        <div
          key={index}
          className="max-h-9 w-1 rounded-full"
          style={{ height: vol === 0 ? '2px' : `${vol / 5}px`, backgroundColor: bgColor }}
        ></div>
      ))}
    </div>
  );
}

export default VoiceVisualizer;