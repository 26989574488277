import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function OrganizationDropdownList(props){
    const menu = useRef(null);
    const label = props?.label || 'Change org';
    const [menuVisibility, setMenuVisibility] = useState(false);

    const getMenus = (data) => {
        return data?.map((item) => (
            {
                label: item['name'],
                command: () => {
                    props?.setSelectedOrganization(item);
                    setMenuVisibility(false);
                }
            }
        ))
    }

    const menus = getMenus(props.data);

    const onClick = (event) => {                       
            if(menuVisibility) menu.current.hide(event)
            else menu.current.show(event)
            setMenuVisibility(!menuVisibility)
    }

    return (
        <div 
            onClick={onClick} 
            className="bg-white overflow-y-scroll max-h-1/2 flex px-3 py-2 gap-5 justify-between items-center rounded cursor-pointer relative w-40 border-[1px] border-[#EAECF0]">
            <h4 className="text-sm">{label}</h4>
            {!menuVisibility && <MdOutlineKeyboardArrowDown size={22} /> }
            {menuVisibility && <MdOutlineKeyboardArrowUp size={22} /> }
            <Menu 
                popup 
                ref={menu}
                model={menus}
                popupAlignment="left"
                className="w-40 h-1/2 overflow-y-scroll"
            />
        </div>
    )
}