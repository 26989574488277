import moment from 'moment';
import PropTypes from 'prop-types';
import { Column } from 'primereact/column';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import logo from './../../../assets/icons/logo.svg';
import { BsThreeDotsVertical } from "react-icons/bs";
import JDService from "../../../services/jd.service";
import editIcon from './../../../assets/icons/edit.svg';
import ConsoleHelper from '../../../utils/consoleHelper';
import { FaToggleOff, FaToggleOn} from "react-icons/fa6";
import LoadingComponent from '../../Common/LoadingComponent';
import { useAuth } from '../../../contextProviders/AuthContext';
import { useToast } from '../../../contextProviders/ToastContext';
import { useJDInfo } from '../../../contextProviders/JDInfoContext';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/common';
import { contentSkeleton, circleSkeleton } from '../../Common/TableLoadingComponents';
import { InputText } from 'primereact/inputtext';
import CustomPaginator from '../../Common/CustomPaginator';


const InitialPaginationDetails = {
  totalPage: 0,
  totalCount: 0,
  currentPage: 1,
  limit: 10,
};
function HRDashboardJDSection(props){
    const {user} = useAuth();
    const navigate = useNavigate();
    const [jds, setJDs] = useState();
    const {clearJD, changeJDInfo} = useJDInfo();
    const [loading, setLoading] = useState(false);
    const {selectedJD, handleSelectedJd, dateRange} = props;
    const [uploading, setUploading] = useState(false);
    
    const [clickedOn, setClickedOn] = useState(null);
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [paginationData, setPaginationData] = useState(InitialPaginationDetails);
    useEffect(() => {
        async function getJDs(){
            try {
                setLoading(true);
                const response = await JDService.getJds(paginationData.currentPage, paginationData.limit, null, null, null, dateRange);
                setLoading(false);
                if(response.status === 200){
                    const {data, totalCount, totalPage, currentPage, limit} = response.data;
                    setJDs(data)
                    setPaginationData({
                            totalCount,
                            totalPage,
                            currentPage,
                            limit })}
            } catch (error) {
                setLoading(false);
                ConsoleHelper.log(error?.response?.data?.message)
            }
        }
        getJDs();
    }, [dateRange, paginationData.currentPage, paginationData.limit])

    const filteredJDs = jds?.filter(jd => 
        jd?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase() || "")
    ) || [];
    
    const renderPrimarySkills = ({primarySkills}) => {
        const list = primarySkills[0]?.name ? primarySkills?.map((item) => item?.name) : primarySkills;
        return (
            <div className="flex gap-4 text-sm items-center w-40">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {list?.join(", ")}
                </span>
            </div>
        )
    }


    const renderLocation = ({location}) => {
        return (
            <div className="flex gap-4 text-sm items-center w-40">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {(!location || location?.length === 0 || location[0] == null) ?  '-' : location?.map((item) => capitalizeFirstLetterOfEachWord(item))?.join(",")}
                </span>
            </div>
        )
    }

    const renderJobTitle = (item) => {
        return (
            <div className="flex gap-4 text-sm items-center w-44">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>{item?.title}</span>
            </div>
        )
    }
    
    const resumeCount = ({resumeCount}) => {
        return (
            <div className='justify-center items-center flex'>
                <div className='h-8 w-8 rounded-full bg-primary justify-center items-center flex'>
                    <span className='text-white font-medium text-xs'>{resumeCount || 0}</span>  
                </div>
            </div>
         )
    }

    const renderCreatedAt = ({createdAt}) => {
        return (
            <div className='items-center flex'>
                <span className='w-40 text-sm'>{moment(createdAt).format('DD MMM yyyy')}</span>
            </div>
         )
    }

    const onEditClick = (item) => {
        clearJD();
        changeJDInfo(item);
        navigate(`/dashboard/job-description/${selectedJD._id}/edit`)
    }

    const inActivateJDClick = () => {
        setClickedOn("inactivate");
        setShowConfirmationDialog(true);
    }

    const activateJDClick = () => {
        setClickedOn("activate");
        setShowConfirmationDialog(true);
    }

    const toggleJDActiveStatus = async () => {
        try {
            setUploading(true);
            const jdResponse = 
                clickedOn === "activate" ? await JDService.activateJD(selectedJD?._id) : await JDService.inactivateJD(selectedJD?._id);
            setUploading(false);
            if(jdResponse.status === 200){
                setShowConfirmationDialog(false);
                setJDs(jds?.map((item => {
                    if(item?._id === selectedJD?._id)
                        return {...item, active: clickedOn === "activate" ? true : false}
                    return item;
                })))
                if(clickedOn === "inactivate"){
                    showSuccessMessage({
                        life: 5000,
                        summary: 'Alert', 
                        detail: "Please ensure there are no active interview invites. Candidate can continue the interview if any invites are sent"
                    })
                }
                else {
                    showSuccessMessage({ summary: 'Success', detail: "JD is activated"})
                }
            } 
        }
        catch (error) {
            setUploading(false);
            setShowConfirmationDialog(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
    }
    const options = (item) => {
        if(user['role'] !== 'hrAdmin' && item?.['user'] !== user['_id']) return;
        
        return (
            <div 
                onClick={(event) => event?.stopPropagation()}
                className={`absolute transform -translate-x-1/2 -translate-y-1/2`}>
                <BsThreeDotsVertical
                    size={20} 
                    color='#666666'
                    className="cursor-pointer"
                    onClick={handleSelectedJd.bind(this, item)}
                />
                <div className={`${selectedJD?._id === item?._id ? 'visible' : 'hidden'} rounded-lg shadow shadow-gray bg-white -top-5 right-0 z-20 absolute`}>
                    <ul className="p-2 gap-2 flex flex-col">
                        <li
                            style={{backgroundColor: 'rgba(60, 153, 202, .1)'}}
                            onClick={onEditClick.bind(this, item)} 
                            className={`py-2 text-blue flex items-center gap-3 px-3 rounded cursor-pointer ${item?.active === false ? 'hidden': 'visible'}`}>
                            <img 
                                alt='edit-icon'
                                src={editIcon}
                                className='h-4 w-4'
                            />
                            <h4 className='text-sm'>Edit</h4>
                        </li>
                        <li 
                            onClick={inActivateJDClick}
                            className={`py-2 text-[#FF3B30] flex items-center gap-3 px-3 justify-between rounded cursor-pointer ${item?.active !== false ? 'visible': 'hidden'}`} 
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <FaToggleOff size={20}/>
                            <h4 className='text-sm bg'>Inactive</h4>
                        </li>
                        <li 
                            onClick={activateJDClick}
                            className={`py-2 text-[#FF3B30] flex items-center gap-3 px-3 justify-between rounded cursor-pointer ${item?.active === false ? 'visible': 'hidden'}`}
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <FaToggleOn size={20} />
                            <h4 className='text-sm'>Active</h4>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }



    return (
        <div className="flex flex-col gap-6" onClick={handleSelectedJd.bind(this, null)}>
            <div className={`${showConfirmationDialog? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-5 rounded-md flex justify-center items-center">
                    {uploading && <LoadingComponent/>}
                    <div className={`${uploading? 'hidden': 'visible'} flex flex-col justify-center items-center gap-5`}>
                        <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex"><img src={logo} className="h-11 w-11" alt="logo" /></div>
                        <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
                        <div className='flex flex-col items-center justify-center'>
                            <p className="text-brownGray text-base">Would you want to {clickedOn === "activate"? "activate" : "inactivate"} the JD?</p>
                        </div>
                        <div className="flex items-center justify-center gap-10 py-5 text-sm">
                            <button className="h-10 border border-primary w-32 font-medium" onClick={() => setShowConfirmationDialog(false)}>NO</button>
                            <button className="bg-primary h-10 rounded w-32 text-white font-medium" onClick={toggleJDActiveStatus}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className='text-xl text-[#161616] font-bold'>Job Description List</h1>
                {/* <div className="flex gap-5 text-brownGray font-normal">
                    <div className="bg-white h-10 px-2 flex rounded justify-center items-center">
                        <InputText
                            className="text-sm h-10" 
                            placeholder="Search"
                            color="#666666"
                        />
                        <IoSearchSharp 
                            size={20} 
                            color="#666666"
                        />
                    </div>
                    <div className="bg-white h-10 px-2 rounded flex items-center gap-5 cursor-pointer">
                        <span className="text-sm ">Experience</span>
                        <MdOutlineKeyboardArrowDown 
                            size={20} 
                        />
                    </div>
                    <div className="bg-white h-10 px-2 rounded flex items-center gap-5 cursor-pointer">
                        <span className="text-sm ">Location</span>
                        <MdOutlineKeyboardArrowDown 
                            size={20} 
                        />
                    </div>
                </div> */}
            <div className="pb-10 bg-white rounded ">
                 <div className="p-5 flex gap-5">
                          <InputText
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                            className="h-8 border border-l_border px-2 text-sm w-1/3"
                            placeholder="Search job title"
                          />
                          {/* <button
                             onClick={() => setSearchTerm(searchTerm)}
                            className="bg-primary text-white px-7 rounded-md"
                          >
                            Search
                          </button> */}
                        </div>
                <DataTable 
                    value={filteredJDs}
                    className="text-sm bg-white" 
                    rowClassName='bg-white hover:text-blue hover:cursor-pointer'
                    emptyMessage={loading ? <LoadingComponent/> : "No Jd available"}
                    onRowClick={({data}) => {
                        navigate(`/dashboard/job-description/${data._id}`, {state: data})
                    }}
                    tableStyle={{ minWidth: '50rem' }}>
                    {user && user?.['role'] === 'admin' && (
                        <Column  
                            header="Organization"
                            field="organizationDetails.name" 
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base' 
                        />
                    )}
                    {user && user?.['role'] === 'admin' && (
                        <Column  
                            header="Hr Name"
                            field="userDetails.firstName" 
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base' 
                        />
                    )}
                    <Column 
                        sortable
                        field="title" 
                        header="Job title" 
                        body={loading ? contentSkeleton() : renderJobTitle}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {/* <Column 
                        field="experience" 
                        header="Experience" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
                    <Column 
                        field="primarySkills" 
                        header="Mandatory Skills" 
                        body={loading ? contentSkeleton() : renderPrimarySkills}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {/* <Column 
                        field="secondarySkills" 
                        header="Optional Skills" 
                        body={renderSecondarySkills} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white bg-white' 
                    /> */}
                    <Column 
                        sortable
                        sortField="resumeCount"
                        header="Resume Count" 
                        body={loading ? circleSkeleton(): resumeCount}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        header="Location"
                        body={loading ? contentSkeleton() : renderLocation}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        sortable
                        field="createdAt" 
                        header="Created Dt"
                        body={loading ? contentSkeleton() : renderCreatedAt}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {user && user['role'] !== 'admin' && (
                        <Column 
                            header="Action"
                            body={loading ? contentSkeleton(): options}
                            className='text-sm 2xl:text-base'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        />
                    )}
                </DataTable>
            </div>
            {paginationData.totalCount > 9 && (
                <CustomPaginator
                    limit={paginationData.limit}
                    defaultRows={10}
                    totalRecords={paginationData.totalCount}
                    setLimit={(newLimit) => setPaginationData(prev => ({ ...prev, limit: newLimit}))}
                    totalPages={paginationData.totalPage}
                    totalCount={paginationData.totalCount}
                    currentPage={paginationData.currentPage}
                    setCurrentPage={(newPage) => setPaginationData(prev => ({ ...prev, currentPage: newPage }))}
                />
                )}
        </div>
    )
}


HRDashboardJDSection.propTypes = {
    selectedJD: PropTypes.object,
    menuVisibility: PropTypes.bool,
    dateRange: PropTypes.string,
    handleSelectedJd: PropTypes.func,
    handleMenuVisibility: PropTypes.func
}

export default HRDashboardJDSection;