import axiosInstance from ".";
import { getDateRange } from "../utils/common";

function getResumesScreening(page, limit, status, search, dateRange){
    if(search)
        return axiosInstance.get(`/resume-screenings?dateRange=${getDateRange(dateRange)}&page=${page}&limit=${limit}&status=${status}&search=${search}`)
    return axiosInstance.get(`/resume-screenings?dateRange=${getDateRange(dateRange)}&page=${page}&status=${status}&limit=${limit}`)
}

function getResumeScreening(resumeId){
    return axiosInstance.get(`/resume-screenings/${resumeId}`)
}

const ResumeScreeningService = {
    getResumesScreening,
    getResumeScreening,
}

export default ResumeScreeningService;