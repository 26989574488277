import { useState } from "react";
import PropTypes from 'prop-types';
import { FaCode } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { BounceLoader } from 'react-spinners';
import { RiAiGenerate } from "react-icons/ri";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { IoMdAddCircleOutline } from "react-icons/io"
import { InputNumber } from 'primereact/inputnumber';
import { useAuth } from "../../../contextProviders/AuthContext";
import { useToast } from "../../../contextProviders/ToastContext";
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import { useConfig } from "../../../contextProviders/ConfigContext";
import CheckboxComponent from "../../../components/Common/CheckboxComponent";
import CommonService from "../../../services/common.service";
import CodingQuestionEditor from "../../../components/JD/CodingQuestionEditor";
import EditORViewCodingQuestionEditor from "../../../components/JD/EditORViewCodingQuestionEditor";

const interviewDifficulties = [
    { name: "Easy", code: "easy"},
    { name: "Medium", code: "medium"},
    { name: "Advanced", code: "advanced"},
    { name: "Expert", code: "expert"},
]

const interviewFocusOnList = [
    { name: "JD Only", code: "JD", description: `Interview questions will be focused on the JD/Role irrespective of candidate's experience`},
    { name: "Resume Only", code: "RESUME", description: `Interview questions will be based on candidate's experience and expertise irrespective of the role description`},
    { name: "JD & Resume", code: "JD_RESUME", description: `Interview questions will be around candidate's experience and expertise in context of the JD`},
    { name: "All Skills", code: "ALL_SKILLS", description: `Interview questions will cover mandatory and optional skills only irrespective of any other info in the JD and the resume`},
    { name: "Mandatory Skills Only", code: "PRIMARY_SKILLS", description: `Interview questions will cover mandatory skills only irrespective of any other info in the JD and the resume`},
]

function JDTechnicalQAsPage(props){
    const {goToPreviousTab, goToNextTab} = props;
    const {user} = useAuth();
    const {showWarningMessage} = useToast();

    const { getJDInfo, changeJDInfo } = useJDInfo();
    const jdInfo = getJDInfo();
    const orgConfig = useConfig();

    const [selectedCodingQuestionIndex, setSelectedCodingQuestionIndex] = useState(null);
    const [showCodingQuestionEditor, setShowCodingQuestionEditor] = useState(false);
    const [showEditORViewCodingQuestionEditor, setShowEditORViewCodingQuestionEditor] = useState(false);

    const technicalScreeningQAs = jdInfo?.["technicalScreeningQAs"] || [];
    const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);

    const [codingQuestions, setCodingQuestions] = useState(jdInfo?.["codingQuestions"] || []);

    const [requireCodingQuestions, setRequireCodingQuestions] = useState(jdInfo?.['requireCodingQuestions'] || false);
    const [interviewDuration, setInterviewDuration] = useState(requireCodingQuestions ? 40 : jdInfo?.['duration'] != null ? jdInfo?.['duration'] : user?.email?.includes('alorica') ? 15 : 30);

    const [evaluationThreshold, setEvaluationThreshold] = useState(jdInfo?.['evaluationThreshold'] || 10);
    const [isAnomalyDetectionRequired, setIsAnomalyDetectionRequired] = useState(jdInfo?.['detectAnomaly'] || false);
    
    const [isSummarizedJDRequired, setIsSummarizedJDRequired] = 
        useState(jdInfo?.['startWithSummary'] == null ? true : jdInfo?.['startWithSummary']);

    const initialInterviewDifficultyValue = 
        typeof jdInfo?.['difficultyLevel'] === "string" ? { name: jdInfo?.['difficultyLevel']?.replace(/\w/, c => c.toUpperCase()), code: jdInfo?.['difficultyLevel'] } :  
        typeof jdInfo?.['difficultyLevel'] === "object" ? jdInfo?.['difficultyLevel']  : 
            interviewDifficulties[1];

    const [interviewDifficulty, setInterviewDifficulty ] = useState(initialInterviewDifficultyValue);
    
    const initialInterviewFocusOnValue = 
        typeof jdInfo?.['interviewFocusOn'] === "string" ? 
            interviewFocusOnList?.filter((item) => item.code === jdInfo?.['interviewFocusOn'])[0] :  
        typeof jdInfo?.['interviewFocusOn'] === "object" ? jdInfo?.['interviewFocusOn']  : 
        orgConfig?.['interviewFocusOn'] ? interviewFocusOnList?.filter((item) => item.code === orgConfig?.['interviewFocusOn'])[0] :  
        interviewFocusOnList[3];

    const [interviewFocusOn, setInterviewFocusOn] = useState(initialInterviewFocusOnValue);

    const [isCheckForInterpersonalSkills, setIsCheckForInterpersonalSkills] = useState(jdInfo?.['isCheckForInterpersonalSkills'] || false);

    const [questionsAnswers, setQuestionsAnswers] = useState(technicalScreeningQAs || [{question: '', answer: ''}]);
    const addQuestionAnswer = () => setQuestionsAnswers([...questionsAnswers, {question: '', answer: ''}]);

    const insertQuestion = (questionIndex, text) => {
        const updatedList = questionsAnswers?.map((item, index) => {
            if(index === questionIndex)
                return {question: text, answer: item?.answer}
            return item;
        });
        setQuestionsAnswers(updatedList);
    }

    const removeQuestion = (questionIndex) => {
        const newList = questionsAnswers?.filter((item, index) => index !== questionIndex);
        setQuestionsAnswers(newList);
    };

    const removeCodingQuestion = (questionIndex) => {
        const newList = codingQuestions?.filter((item, index) => index !== questionIndex);
        setCodingQuestions(newList);
        changeJDInfo({ codingQuestions: newList});
    };

    const addCodingQuestion = (question) => {
        const list = [...codingQuestions, {question}];
        setCodingQuestions(list);
        changeJDInfo({ codingQuestions: list});
    }

    const updateCodingQuestion = (questionIndex, question) => {
        const updatedList = codingQuestions?.map((item, index) => {
            if(index === questionIndex)
                return {question}
            return item;
        });
        setCodingQuestions(updatedList);
        changeJDInfo({ codingQuestions: updatedList});
    }

    const insertAnswer = (answerIndex, text) => {
        const updatedList = questionsAnswers?.map((item, index) => {
            if(index === answerIndex)
                return {question: item?.question, answer: text}
            return item;
        });
        setQuestionsAnswers(updatedList);
    }

    const handleChanges = (key, value) => {
        if (key === 'duration') setInterviewDuration(value)
        else if (key === 'interviewFocusOn') setInterviewFocusOn(value)
        else if (key === 'difficultyLevel') setInterviewDifficulty(value)
        else if(key === 'detectAnomaly') setIsAnomalyDetectionRequired(value)
        else if (key === 'startWithSummary') setIsSummarizedJDRequired(value)
        else if (key === 'evaluationThreshold') setEvaluationThreshold(value)
        else if (key === 'requireCodingQuestions') {
            if(value) setInterviewDuration(40)
            else setInterviewDuration(interviewDuration)
            setRequireCodingQuestions(value)
        }
        else if (key === 'isCheckForInterpersonalSkills') setIsCheckForInterpersonalSkills(value)
        changeJDInfo({[key]: value})
    }

    const goBack = () => {
        goToPreviousTab();
    }

    const goNext = () => {
        if (requireCodingQuestions) {
            if (interviewDuration < 40)
                setInterviewDuration(40);
        
            if (
                !codingQuestions || 
                codingQuestions.length === 0 || 
                (codingQuestions.length === 1 && !codingQuestions[0].question?.trim())
            ) {
                showWarningMessage({
                    life: 5000,
                    summary: 'Coding Question Requirement',
                    detail: 'Please ensure that at least one coding question is included, designed to be solvable by the candidate within 10–12 minutes during the interview.'
                });
                return;
            }
        }
        const data = { 
            codingQuestions: codingQuestions,
            technicalScreeningQAs: questionsAnswers?.filter((item) => item?.question?.trim()?.length > 0 )
        }
        if(!jdInfo['interviewFocusOn'])
            data['interviewFocusOn'] = interviewFocusOn;
        
        changeJDInfo(data);
        goToNextTab();
        
    }

    const generateCodingQuestions = async () => {
        try {
            setIsGeneratingQuestions(true);
            const requestData = {
                job_title: jdInfo['title'],
                skills: jdInfo['primarySkills'].slice(0, 3)?.map((item) => item?.['name'] || item),
            };
            const response = await CommonService.generateCodingQuestions(requestData);
            setIsGeneratingQuestions(false);
            if(response.status === 200){
                const {coding_questions} = response.data;
                const questions = coding_questions?.map((item) => ({question: item['question']}));
                setCodingQuestions(questions)
            }
        } catch (error) {
            setIsGeneratingQuestions(false);
        }
    }

    return (
        <div className="h-full flex flex-col gap-10">
            {showCodingQuestionEditor && (
                <CodingQuestionEditor 
                    addCodingQuestion={addCodingQuestion}
                    hideCodingQuestionEditor={() => setShowCodingQuestionEditor(false)}
                />
            )}
            {showEditORViewCodingQuestionEditor && (
                <EditORViewCodingQuestionEditor 
                    updateCodingQuestion={updateCodingQuestion}
                    selectedCodingQuestionIndex={selectedCodingQuestionIndex}
                    question={codingQuestions[selectedCodingQuestionIndex]?.question}
                    hideCodingQuestionEditor={() => setShowEditORViewCodingQuestionEditor(false)}
                />
            )}
            <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-5 p-5 rounded-md bg-white">
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Anomaly Detection required</span>
                        <CheckboxComponent
                            label="detectAnomaly" 
                            setValue={handleChanges}
                            value={isAnomalyDetectionRequired}
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Check for communication / Interpersonal skills</span>
                        <CheckboxComponent 
                            setValue={handleChanges}
                            label="isCheckForInterpersonalSkills"
                            value={isCheckForInterpersonalSkills}
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Summarize the JD at the beginning of the interview</span>
                        <CheckboxComponent
                            label="startWithSummary" 
                            setValue={handleChanges}
                            value={isSummarizedJDRequired}
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Interview Duration (in minutes)</span>
                        {/* <InputText 
                            value={interviewDuration}
                            onChange={(e) => handleChanges('duration', e.target.value)}
                            className="border h-8 w-10 px-1 text-center"  
                        /> */}
                        <InputNumber
                            min={20}
                            max={90}  
                            value={interviewDuration}
                            onValueChange={(e) => handleChanges('duration', e.value)} 
                            className="h-10 text-center border-[1px] rounded-md text-sm"
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <div className="flex flex-col  w-full">
                            <span className="text-base">* Threshold in evaluation scoring (10-30 %)</span>
                            <span className="text-do text-sm">Note: How lenient you want us to be in scoring</span>
                        </div>
                        <InputNumber  
                            min={10}
                            max={30}
                            value={evaluationThreshold}
                            className="h-10 text-center border-[1px] rounded-md text-sm"
                            onValueChange={(e) => handleChanges('evaluationThreshold', e.value)} 
                        />
                    </div>
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Interview questions Difficulty level (default level is medium)</span>
                        <Dropdown
                            optionLabel="name"   
                            className="border text-sm"
                            value={interviewDifficulty}
                            placeholder="Questions Difficulty"
                            onChange={(e) => handleChanges('difficultyLevel', e.value)} 
                            options={interviewDifficulties} 
                            pt={{
                                root: {className: 'flex items-center pr-2'},
                                input: {className: 'text-sm h-10 py-2.5'},
                                item: {className: 'text-xs h-10'},
                                clearIcon: {className: 'text-xs h-3 w-3'},
                                trigger: {className: 'text-xs h-3 w-3'}
                            }}
                        />
                    </div>
                    <div className="flex flex-col">
                        <div className="flex justify-between items-center">
                            <span className="text-base w-full">* Interview questions focus on</span>
                            <Dropdown
                                optionLabel="name"   
                                className="border text-sm"
                                value={interviewFocusOn}
                                placeholder="Interview Focus On"
                                onChange={(e) => handleChanges('interviewFocusOn', e.value)} 
                                options={interviewFocusOnList} 
                                pt={{
                                    root: {className: 'flex items-center pr-2'},
                                    input: {className: 'text-sm h-10 py-2.5'},
                                    item: {className: 'text-xs h-10'},
                                    clearIcon: {className: 'text-xs h-3 w-3'},
                                    trigger: {className: 'text-xs h-3 w-3'}
                                }}
                            />
                        </div>
                        {interviewFocusOn.description && <span className="text-do text-sm">Note: {interviewFocusOn.description}</span> }
                    </div>
                </div>
                <div className="text-darkGray flex flex-col gap-5 rounded-md bg-white p-5">
                        <h2 className="text-base text-darkGray">Add any additional questions to be asked (OPTIONAL)</h2>
                        <div className="flex gap-5">
                            <div 
                                onClick={addQuestionAnswer}
                                className="border-blue border cursor-pointer text-blue px-2 h-10 rounded font-medium text-zs flex items-center gap-4 w-32">
                                <IoMdAddCircleOutline size={20}/>
                                <span>Add Q&A</span>
                            </div>
                        </div>
                        <div className={`${questionsAnswers?.length > 0 ? 'visible': 'hidden'} flex flex-col gap-4`}>
                            {questionsAnswers?.map((item, index) => (
                                <div 
                                    key={index?.toString()}
                                    className="flex shadow-md gap-2 p-4 rounded-md justify-center items-center">
                                    <div className="flex flex-col gap-2 flex-1">
                                        <div 
                                            className="flex flex-col gap-2">
                                            <h1 className="font-semibold text-xs">Question {index + 1}</h1>
                                            <InputText
                                                value={item.question}
                                                placeholder="Enter question"
                                                className="border border-[#EAECF0] h-10 px-2"
                                                onChange={(event) => insertQuestion(index, event.target.value)}
                                            />
                                        </div>
                                        <div 
                                            key={index?.toString()}
                                            className="flex flex-col gap-2">
                                            <h1 className="font-semibold text-xs">Answer {index + 1}</h1>
                                            <InputText
                                                value={item.answer}
                                                placeholder="Enter answer"
                                                className="border border-[#EAECF0] h-10 px-2"
                                                onChange={(event) => insertAnswer(index, event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div 
                                        onClick={removeQuestion.bind(this, index)}
                                        className="h-8 w-8 shadow-md justify-center flex items-center rounded-full cursor-pointer hover:bg-primary hover:text-white">
                                        <MdClear size={18}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
                <div className="text-darkGray flex flex-col gap-5 rounded-md bg-white p-5">
                    <div className="flex gap-5 items-center">
                        <span className="text-base w-full">* Coding questions to be asked?</span>
                        <CheckboxComponent 
                            setValue={handleChanges}
                            label="requireCodingQuestions"
                            value={requireCodingQuestions}
                            className='border-primary'
                        />
                    </div>
                    <div className={`${requireCodingQuestions ? 'flex gap-5 visible' : 'hidden'}`}>
                        <div 
                            onClick={() => setShowCodingQuestionEditor(true)}
                            className="border-blue border cursor-pointer text-blue px-2 h-10 rounded font-medium text-zs flex items-center gap-4">
                            <IoMdAddCircleOutline size={20}/>
                            <span>Add coding question</span>
                        </div>
                        <div 
                            onClick={generateCodingQuestions}
                            className="border-blue border cursor-pointer text-blue px-2 h-10 rounded font-medium text-zs flex items-center gap-1 w-40">
                            <RiAiGenerate size={20}/>
                            <div className='flex justify-between items-center flex-1'>
                                {isGeneratingQuestions ? 
                                    <BounceLoader size={26} color="#3C99CA" /> : 
                                    <span className="font-semibold text-xs">Generate Questions</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${codingQuestions?.length > 0 && requireCodingQuestions ? 'visible': 'hidden'} flex flex-col gap-4`}>
                        <h1 className='text-do'>Note: One of the following coding questions to be asked</h1>
                        {codingQuestions?.map((item, index) => (
                            <div 
                                key={index?.toString()}
                                className="flex shadow-md gap-2 p-4 rounded-md justify-center items-center">
                                <div className="flex flex-col gap-2 flex-1">
                                    <div 
                                        className="flex flex-col gap-2">
                                        <h1 className="font-semibold text-xs">Question {index + 1}</h1>
                                        <div className='flex gap-2'>
                                            <InputText
                                                value={item.question}
                                                contentEditable={false}
                                                autoFocus={false}
                                                className="border border-[#EAECF0] h-10 px-2 flex-1"
                                            />
                                            <div 
                                                onClick={() => {
                                                    setSelectedCodingQuestionIndex(index);
                                                    setShowEditORViewCodingQuestionEditor(true);
                                                }}
                                                className="h-8 w-8 shadow-md justify-center flex items-center rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white">
                                                <FaCode size={18}/>
                                            </div>
                                            <div 
                                                onClick={removeCodingQuestion.bind(this, index)}
                                                className="h-8 w-8 shadow-md justify-center flex items-center rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white">
                                                <MdClear size={18}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-between">
                <button 
                    onClick={goBack}
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-32 mt-10'>
                    Back
                </button>
                <button
                    onClick={goNext} 
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-10'>
                    Next
                </button>
            </div>
        </div>
    )
}

JDTechnicalQAsPage.propTypes = {
    onFinalizedJD: PropTypes.func,
    setActiveTabIndex: PropTypes.func
}

export default JDTechnicalQAsPage;