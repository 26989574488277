import React from 'react';
import { useCallback } from 'react';
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { CgFileDocument } from "react-icons/cg";
import { DataTable } from "primereact/datatable";
import { InputText } from 'primereact/inputtext';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { convertUTCToLocal } from "../../../utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from '../../../contextProviders/AuthContext';
import ResumeViewerPopup from '../../../components/JD/ResumeViewerPopup';
import CustomPaginator from '../../../components/Common/CustomPaginator';
import { usePageData } from '../../../contextProviders/PagesDataContext';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import ResumeScreeningService from '../../../services/resumeScreening.service';
import { contentSkeleton } from '../../../components/Common/TableLoadingComponents';

export default function ResumesScreeningPage(){
    const navigate = useNavigate();
    const {user} = useAuth();
    
    const location = useLocation();
    const state = location?.state;
    const dateRange = state?.dateRange;
    const status = state?.status;
    const goBack = () => navigate(-1);
    const [resumeUrl, setResumeUrl] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState(null);

    const {updatePageData, getPageData } = usePageData();
    const sessionsPage = getPageData('sessions-page') || {};

    const [showResumePopup, setShowResumePopup] = useState(false);
    const [first, setFirst] = useState(sessionsPage?.['first'] || 0);
    const [limit, setLimit] = useState(sessionsPage?.['limit'] || 10);
    const [resumes, setResumes] = useState(sessionsPage?.['sessions'] ||[]);
    const [totalCount, setTotalCount] = useState(sessionsPage?.['totalCount'] || 0);
    const [totalPage, setTotalPage] = useState(sessionsPage?.['totalPage'] || 0);
    const [currentPage, setCurrentPage] = useState(sessionsPage?.['currentPage'] || 1);

    useEffect(() => {
        const handleResize = () => setLimit(10);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const getSessions = async () => {
        try {
            setLoading(true);
            let response = null;
            if(searchText && searchText?.trim()?.length)
                response = await ResumeScreeningService.getResumesScreening(currentPage, limit, status, searchText, dateRange);
            else response = await ResumeScreeningService.getResumesScreening(currentPage, limit, status, null, dateRange);

            setLoading(false);
            if(response.status === 200){
                const {data, totalCount, totalPage} = response.data || []; 
                const list = data?.map((item) => ({...item, selected: false}))
                setResumes(list);
                setTotalCount(totalCount);
                setTotalPage(totalPage);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSessions();
    }, [currentPage, limit]);

    const renderEmail = ({meta, _id}) => {
        const email = meta?.['Email'] || meta?.['email'];

        if(email){
            return (
                <div className="flex gap-4 items-center">
                    <span className='text-ellipsis overflow-hidden whitespace-nowrap'>{email}</span>
                </div>
            )
        }

        return (
            <div className="flex gap-4 items-center">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>Not Available</span>
            </div>
        )
    }

    const renderName = ({meta}) => {
        return (
            <div className="flex gap-4 items-center">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {(meta?.['Name'] || meta?.['name']) ?meta?.['Name'] || meta?.['name'] : 'Not Available'}
                </span>
            </div>
        )
    }


    const showResume = (item) => {
        if(item['resumeUrl'])
            setResumeUrl(item['resumeUrl']);
        setShowResumePopup(true);
    }

    const viewResume = (item) => {
        return (
            <div 
                onClick={(event) => event?.stopPropagation()}
                className="flex gap-4 items-center w-10">
                <CgFileDocument 
                    size={20}
                    onClick={showResume.bind(this, item)}
                    className='cursor-pointer text-primary'
                />
            </div>
        )
    }


    const renderScore = useCallback(({score}) => {
        if(isNaN(parseInt(score))){
            return (
                <div className="flex items-center justify-center">
                    <span className="text-sm">-</span>
                </div>
            )
        }

        function getTheme(){
            if(score >= 75) return 'bg-lg text-dg';
            else if(score >= 40) return 'bg-lo text-do';
            else if(score > 0) return 'bg-lr text-dr';
        }

        return (
            <div className="flex items-center gap-5">
                <div className={`font-medium text-xs px-4 p-2 justify-center items-center flex rounded-full ${getTheme()} `}>
                    <span className="text-xs font-semibold">{parseInt(score) > 0 ? `${parseFloat(score)?.toFixed(2)}%` : '-'}</span>
                </div>
            </div>
        )
    }, []);

    const renderInterviewStartTime = ({startTime, createdAt}) => {
        return (
            <div className=''>
                <span className='text-sm'>{convertUTCToLocal(startTime || createdAt)}</span>
            </div>
         )
    }

    return (
        <div className="flex flex-col h-full gap-4 p-5">
            <div className="flex items-center gap-4 text-darkGray">
                <div 
                    className="flex items-center gap-2 cursor-pointer">
                    <MdKeyboardArrowLeft onClick={goBack} size={26} />
                    <h1 className='text-xl text-primary font-bold cursor-pointer'>Resumes</h1>
                </div>
            </div>
            {showResumePopup && (
                <ResumeViewerPopup 
                    resumeUrl={resumeUrl}
                    header="Resume"
                    close={() => setShowResumePopup(false)}
                />
            )}
            <div className="flex gap-5">
                <InputText
                    value={searchText}
                    placeholder="Search resumes by email or name"
                    onChange={(event) => {
                        if(!event.target.value || event.target.value?.trim()?.length === 0)
                            getSessions();
                        setSearchText(event.target.value);
                    }}
                    className="border border-[#EAECF0] h-8 px-2 w-1/3 text-sm font-medium text-primary"
                />
                <button
                    onClick={() => {
                        if(currentPage !== 1)
                            setCurrentPage(1)
                        else getSessions();
                    }} 
                    disabled={!searchText || searchText?.trim()?.length === 0}
                    className={`bg-blue text-white h-8 px-4 rounded-md text-sm font-semibold ${!searchText || searchText?.trim()?.length === 0 ? 'opacity-50': 'opacity-100'}`}>
                    Search
                </button>
            </div>
            <div className="bg-white rounded">
                <DataTable
                    dataKey="_id"
                    editMode="row" 
                    value={resumes}
                    selectionMode='checkbox'
                    className="text-brownGray"
                    emptyMessage={loading ? <LoadingComponent/> : "No sessions available"}
                    rowClassName='bg-white hover:text-blue hover:cursor-pointer'
                    onRowClick={({data}) => {
                        if((parseInt(data?.score) <= 0 || isNaN(parseInt(data?.score)))) 
                            return;
                        else if(data['status'] === 'PENDING') 
                            return;
                        
                        const props = {
                            limit,   
                            first,
                            totalPage,
                            totalCount,
                            currentPage,
                            sessions: resumes,
                        };
                        updatePageData('sessions-page', props)

                        const state = {
                            jobTitle: data?.['jdTitle'],
                            candidate: data?.['meta'],
                        };
                        navigate(`/dashboard/resume-screening/${data?.['_id']}`, { state })
                    }}>
                    <Column 
                        body={loading ? contentSkeleton() : viewResume}  
                        className='w-10 p-0 pl-3'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    {user?.['role'] === 'admin' && (loading ? contentSkeleton() : 
                        <Column  
                            sortable
                            sortField='organization.name'
                            header="Organization" 
                            field='organization.name'
                            className='text-sm 2xl:text-base w-1/5'
                            headerClassName='text-sm 2xl:text-base bg-white' 
                        />
                    )}
                    {loading ? contentSkeleton() : <Column  
                        sortable
                        sortField='jdTitle'
                        header="Title" 
                        field='jdTitle'
                        className='text-sm 2xl:text-base w-1/5'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />}
                    <Column  
                        sortable
                        sortField='meta.Name'
                        header="Name" 
                        body={loading ? contentSkeleton() : renderName}
                        className='text-sm 2xl:text-base w-1/5'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        sortable
                        sortField='meta.Email'
                        header="Email" 
                        body={loading ? contentSkeleton() : renderEmail}
                        className='text-sm 2xl:text-base w-1/5'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        sortable
                        sortField='score'
                        body={loading ? contentSkeleton() : renderScore} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                        header='Resume Score'
                    />
                    {/* <Column 
                        header='Status'
                        field='status'
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
                    <Column 
                        field="startTime"
                        header='Resume Upload Date' 
                        className='text-sm 2xl:text-base w-1/4'
                        headerClassName='text-sm 2xl:text-base bg-white'
                        body={loading ? contentSkeleton() : renderInterviewStartTime} 
                    />
                </DataTable>
            </div>
            {totalCount > 9 && (
                <CustomPaginator
                    limit={limit}
                    defaultRows={10}
                    totalRecords={resumes}
                    setLimit={setLimit}
                    totalPages={totalPage}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
        </div>
    )
};