import PropTypes from 'prop-types';
import { Dialog } from "primereact/dialog";
import { ProgressBar } from 'primereact/progressbar';

function RecordingUploadComponent(props){
    const {visible, chunksStatus} = props;

    const trueCount = Object.values(chunksStatus || {}).filter(value => value === true).length;
    const progress = ((trueCount / Object.keys(chunksStatus || {}).length) * 100).toFixed(0);

    if(visible){
        return (
            <Dialog 
                visible={true}
                closable={false}
                draggable={false}
                className="w-1/3 text-primary"
                header='Uploading Interview Data'>
                <div className='flex flex-col justify-center items-center gap-5'>
                    <p className="text-sm text-center text-dr font-semibold mt-2">
                        DO NOT CLOSE THE WINDOW WHILE VIDEO UPLOAD IS IN PROGRESS
                    </p>
                    <ProgressBar 
                        value={isNaN(progress) ? 0 : progress}
                        className='h-5 rounded-full w-full text-xs font-medium mt-5'
                    />
                    <p className="text-sm text-center font-medium">
                        We're currently uploading your interview recordings. This process ensures that all your responses are safely stored.
                    </p>
                </div>
            </Dialog>
        )    
    }

    return <div className="hidden"/>
}

RecordingUploadComponent.propTypes = {
    visible: PropTypes.bool,
    chunksStatus: PropTypes.object
}

export default RecordingUploadComponent;