import { useState } from "react";
import { BounceLoader } from 'react-spinners';
import { MdOutlineReport } from "react-icons/md";
import LogService from "../../services/log.service";
import CommonService from "../../services/common.service";
import { useSessionContext } from "../../contextProviders/SessionContext";

export default function InterviewDisconnectPopup(props){
    const {sessionInfo} = useSessionContext();
    const [isLoading, setIsLoading] = useState(false);
    const token = sessionInfo?.token || localStorage.getItem('sessionToken');

    const reportIssue = async () => {
        try {
            setIsLoading(true);
            LogService.uploadLog(token, sessionInfo?.session_id, `reporting interview issue`);

            const userAgent = navigator.userAgent;
            const appName = navigator.appName;
            const appVersion = navigator.appVersion;
            const platform = navigator.platform;
            const language = navigator.language;

            const browserInformation = { userAgent, appName, appVersion, platform, language };

            const startTime = Date.now();
            await fetch("https://www.google.com/favicon.ico", { method: "HEAD" }); // A small resource
            const endTime = Date.now();
            const userPing = endTime - startTime; // Ping in milliseconds

            await CommonService.reportInterviewIssue(token, {userPing, browserInformation});
            setIsLoading(false);
            props.onDisconnect();
            LogService.uploadLog(token, sessionInfo?.session_id, `reported interview issue ${JSON.stringify({userPing, browserInformation})}`);
        } catch (error) {
            setIsLoading(false);
            props.onDisconnect();
            LogService.uploadLog(token, sessionInfo?.session_id, `failed to report interview issue ${JSON.stringify(error)}`);
        }
    }

    return (
        <div className="h-screen w-screen bg-transparent absolute top-0 left-0 flex z-50 justify-center items-center">
            <div className="bg-white w-1/2 shadow-md drop-shadow-md p-5 rounded-md flex flex-col gap-5">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold text-do">Connection lost</h2>
                    <MdOutlineReport 
                        size={28}
                        className="text-primary"
                    />
                </div>
                <p className="font-normal">We encountered an unexpected issue with continuing the interview. Please retake the interview at your convenience and contact support@athmick.com for further assistance.
                </p>
                <button 
                    disabled={isLoading}
                    onClick={reportIssue}
                    className="bg-primary h-10 w-40 m-auto text-white font-medium text-sm rounded-md">
                    {isLoading ?    (
                        <BounceLoader 
                            size={26} 
                            color="#ffffff" 
                            className="m-auto" 
                        />
                    ) : (
                        <span 
                            className="font-semibold text-xs">
                            Report the issue
                        </span>
                    )}
                                            
                </button>
            </div>
        </div>
    )
}