import axiosInstance from ".";

function getJDResumes(jdId, page=1, limit=10, sort, sortField, search, stage, status){
    let query = `page=${page}&limit=${limit}`
    if(search) query += `&text=${search}`
    if(stage) query += `&stage=${stage}`
    if(status) query += `&status=${status}`
    if(sort && sortField)
        return axiosInstance.get(`/jds/${jdId}/resumes?${query}&sort=${sort}&sortField=${sortField}`) 
    return axiosInstance.get(`/jds/${jdId}/resumes?${query}`)
}

function getResumes(interviewType, page, limit){
    if(interviewType) return axiosInstance.get(`/resumes?interviewType=${interviewType}&limit=${limit}&page=${page}`);
    return axiosInstance.get(`/resumes?limit=${limit}&page=${page}`);
}

function reparseResume(resmeId){
    return axiosInstance.get(`/resumes/${resmeId}/reparse`);
}

function searchResumes(searchText){
    return axiosInstance.get(`/resumes/search?text=${searchText}`);
}

function updateResume(resumeId, data){
    return axiosInstance.put(`/resumes/${resumeId}`, data)
}

function uploadResumes(data){
    const requestData = {};
    if(data['urls']) requestData['urls'] = data?.urls;
    else if (data['resumeIds']) requestData['resumeIds'] = data?.resumeIds;

    return axiosInstance.post(`/jds/${data?.jdId}/resumes`, requestData)
}

function sortlistResumes(jdId, data){
    return axiosInstance.post(`/jds/${jdId}/sortlist-resumes`, data)
}

function getRecordings(resumeId){
    return axiosInstance.get(`/resumes/${resumeId}/recordings`,)
}

function getResumesByIds(ids){
    return axiosInstance.get(`/resumes/batch?ids=${ids?.join(",")}`,)
}

function getTimeline(resumeId){
    return axiosInstance.get(`/resumes/${resumeId}/timeline`,)
}

const ResumeService = {
    reparseResume,
    updateResume,
    getResumes,
    getRecordings,
    getJDResumes,
    uploadResumes,
    sortlistResumes,
    getResumesByIds,
    searchResumes,
    getTimeline,
}

export default ResumeService;