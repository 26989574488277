import axiosInstance from ".";

function getUsers(page=1, limit=10){
    return axiosInstance.get(`/users?page=${page}&limit=${limit}`)
}

function getUser(userId){
    return axiosInstance.get(`/users/${userId}`)
}

function createUser(data){
    return axiosInstance.post('/users', data)
}

function updateUser(userId, data){
    return axiosInstance.put(`/users/${userId}`, data)
}

function assignOrganization(userId, data){
    return axiosInstance.put(`/users/${userId}/assign-org`, data)
}

const UserService = {
    getUser,
    getUsers,
    createUser,
    updateUser,
    assignOrganization,
}

export default UserService;