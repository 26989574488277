import React, { useState, useEffect } from 'react';

function BotVoiceVisualizer(props) {
    const {bgColor= '#ffffff', audioRef, audioContext } = props;
    const [volume, setVolume] = useState([0, 0, 0, 0, 0]);

    useEffect(() => {
        try {
            
            if (!audioRef.current || !audioContext) return;

            const analyserNode = audioContext.createAnalyser();
            analyserNode.fftSize = 2048; // Determines frequency resolution
        
            // Get the audio source and connect it to the analyser
            const source = audioContext.createMediaElementSource(audioRef.current);
            source.connect(analyserNode);
            analyserNode.connect(audioContext.destination);
        
            const bufferLength = analyserNode.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            const visualizeAudio = () => {
                if(!analyserNode) return;

                analyserNode.getByteFrequencyData(dataArray);

                const lowerHalfArray = dataArray.slice(0, dataArray.length / 2);
                const chunkSize = Math.floor(lowerHalfArray.length / 5);

                const newVolumeLevels = [
                    lowerHalfArray.slice(0, chunkSize).reduce((a, b) => a + b, 0) / chunkSize,
                    lowerHalfArray.slice(chunkSize, chunkSize * 2).reduce((a, b) => a + b, 0) / chunkSize,
                    lowerHalfArray.slice(chunkSize * 2, chunkSize * 3).reduce((a, b) => a + b, 0) / chunkSize,
                    lowerHalfArray.slice(chunkSize * 3, chunkSize * 4).reduce((a, b) => a + b, 0) / chunkSize,
                    lowerHalfArray.slice(chunkSize * 4).reduce((a, b) => a + b, 0) / chunkSize,
                ];
                setVolume(newVolumeLevels);

                requestAnimationFrame(visualizeAudio);
            };

            visualizeAudio();

            return () => {
                if (audioContext) audioContext.close();
            };
        } catch (error) {
            if (audioContext) audioContext.close();
        }
    }, []); 

    if(volume[0] === 0){
        return (
            <div className="flex gap-1 justify-center items-center bg-white opacity-70 h-10 w-24 rounded-full z-50 bottom-2 absolute">
                <h3 className='text-sm'>Athmiya</h3>
            </div>
        );
    }

    return (
        <div className="flex gap-1 justify-center items-center bg-white opacity-70 h-10 w-24 rounded-full z-50 bottom-2 absolute">
            {volume.map((vol, index) => (
                <div
                    key={index}
                    className="max-h-9 w-1 rounded-full"
                    style={{ height: vol === 0 ? '2px' : `${vol / 5}px`, backgroundColor: '#2B4360' }} />
            ))}
        </div>
    );
}

export default BotVoiceVisualizer;