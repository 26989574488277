import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import logo from "./../../../assets/icons/logo.svg";
import JDService from "../../../services/jd.service";
import addIcon from "./../../../assets/icons/add.svg";
import ConsoleHelper from "../../../utils/consoleHelper";
import { getPaginationLimitForJD } from "../../../utils/common";
import { useToast } from "../../../contextProviders/ToastContext";
import OrganizationService from "../../../services/organization.service";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import { circleSkeleton, contentSkeleton } from "../../../components/Common/TableLoadingComponents";

export default function OrganisationManagement() {
  const navigate = useNavigate();
  const [first, setFirst] = useState(0);

  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [uploading, setUploading] = useState(false);
  const [selectedJD, setSelectedJD] = useState(null);

  const [clickedOn, setClickedOn] = useState(null);

  const [organisations, setOrganisations] = useState([]);
  const { showErrorMessage, showSuccessMessage } = useToast();

  const [rows, setRows] = useState(getPaginationLimitForJD());
  const [limit, setLimit] = useState(getPaginationLimitForJD());

  const [filteredOrganisations, setFilteredOrganisations] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setCurrentPage(event.page + 1);
  };

  useEffect(() => {
    // clearJD();

    const handleResize = () => {
      setLimit(getPaginationLimitForJD());
      setRows(getPaginationLimitForJD());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function getOrganizations() {
      try {
        setLoading(true);
        const response = await OrganizationService.getOrganizations(
          currentPage,
          limit
        );
        setLoading(false);
        if (response.status === 200) {
          const { data, totalCount, totalPage } = response.data;
          setOrganisations(data);
          setFilteredOrganisations(response.data.data);
          setTotalCount(totalCount);
          setTotalPage(totalPage);
        }
      } catch (error) {
        setLoading(false);
        ConsoleHelper.error(error?.response?.data?.message);
      }
    }
    getOrganizations();
  }, [currentPage, limit]);

  const handleSearch = () => {
    if (!Array.isArray(organisations)) return; 
    if (!searchTerm) {
      setFilteredOrganisations(organisations);
    } else {
      const filtered = organisations.filter(org =>
        org?.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        org?.organizationID?.toLowerCase()?.includes(searchTerm.toLowerCase())
      );
      setFilteredOrganisations(filtered);
    }
  };
  const onRowClick = ({ data }) => {
    navigate(`/dashboard/organisations-management/${data._id}`, { state: data });
  };

  const toggleJDActiveStatus = async () => {
    try {
      setUploading(true);
      const jdResponse =
        clickedOn === "activate"
          ? await JDService.activateJD(selectedJD?.id)
          : await JDService.inactivateJD(selectedJD?.id);
      setUploading(false);
      if (jdResponse.status === 200) {
        setShowConfirmationDialog(false);
        setOrganisations(
          organisations?.map((item) => {
            if (item?._id === selectedJD?.id)
              return {
                ...item,
                active: clickedOn === "activate" ? true : false,
              };
            return item;
          })
        );
        setSelectedJD(null);
        if (clickedOn === "inactivate") {
          showSuccessMessage({
            life: 5000,
            summary: "Alert",
            detail:
              "Please ensure there are no active interview invites. Candidate can continue the interview if any invites are sent",
          });
        } else {
          showSuccessMessage({
            summary: "Success",
            detail: "JD is activated",
          });
        }
      }
    } catch (error) {
      setUploading(false);
      setShowConfirmationDialog(false);
      showErrorMessage({
        summary: "Failed",
        detail: error?.response?.data?.message,
      });
    }
  };

  const renderOrgImage = ({ logo }) => {
    return (
      <div>
        <img src={logo} alt="logo" className="h-10 w-10 rounded-full" />
      </div>
    );
  };
  const renderOrgId = ({ organizationID }) => {
    return (
      <div className="flex gap-4 items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {organizationID || "Not Available"}
        </span>
      </div>
    );
  };
  const renderOrgName = ({ name }) => {
    return (
      <div className="flex gap-4 items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {name || "Not Available"}
        </span>
      </div>
    );
  };

  const renderUserCount = ({ userCount }) => {
    return (
      <div className="h-8 w-8 flex items-center justify-center bg-primary rounded-full">
        <span className="text-white font-medium">{userCount}</span>
      </div>
    );
  };

  const renderWebsite = ({ website }) => {
    return (
      <div className="flex gap-4 items-center">
        {website ? (
          <span
            onClick={() => window.open(website, "_blank")}
            className="underline text-blue-500 cursor-pointer text-ellipsis overflow-hidden whitespace-nowrap"
            title={website}
          >
            {website}
          </span>
        ) : (
          <span className="text-gray-500">Not Available</span>
        )}
      </div>
    );
  };

  const renderIndustry = ({ industry }) => {
    return (
      <div className="flex gap-4 items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {industry || "Not Available"}
        </span>
      </div>
    );
  };
  const renderStatus = ({ status }) => {
    return (
      <div className="flex gap-4 items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {status || "Not Available"}
        </span>
      </div>
    );
  };

  return (
    <div
      onClick={() => setSelectedJD(null)}
      className="flex flex-col h-full gap-4 p-5"
    >
      <div
        className={`${
          showConfirmationDialog ? "visible" : "hidden"
        } absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}
      >
        <div className="bg-white p-5 rounded-md flex justify-center items-center">
          {uploading && <LoadingComponent />}
          <div
            className={`${
              uploading ? "hidden" : "visible"
            } flex flex-col justify-center items-center gap-5`}
          >
            <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex">
              <img src={logo} className="h-11 w-11" alt="logo" />
            </div>
            <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
            <div className="flex flex-col items-center justify-center">
              <p className="text-brownGray text-base">
                Would you want to{" "}
                {clickedOn === "activate" ? "activate" : "inactivate"} the JD?
              </p>
            </div>
            <div className="flex items-center justify-center gap-10 py-5 text-sm">
              <button
                className="h-10 border border-primary w-32 font-medium"
                onClick={() => setShowConfirmationDialog(false)}
              >
                NO
              </button>
              <button
                className="bg-primary h-10 rounded w-32 text-white font-medium"
                onClick={toggleJDActiveStatus}
              >
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <h1 className="text-[#161616] text-xl font-bold">Organisation list</h1>
        <div
          onClick={() => navigate("/dashboard/organisations-management/create")}
          className="bg-blue cursor-pointer text-white px-4 h-10 rounded font-semibold text-sm flex items-center gap-4"
        >
          <span>Create Organisation</span>
          <img alt="add" src={addIcon} className="h-5 w-5" />
        </div>
      </div>
      {/* <div className='flex gap-4'>
                <SearchbarComponent 
                    label={search}
                    onChange={setSearch}              
                />
                <ExperienceDropdownComponent 
                    label={selectedExperience}
                    setLabel={setSelectedExperience}
                />
                <LocationDropdownComponent 
                    label={selectedLocation}
                    setLabel={setSelectedLocation}
                />
            </div> */}
      <div className="bg-white rounded mt-5">
        <div className="p-5 flex gap-5">
          <InputText
            className="h-8 border border-l_border px-2 text-sm w-1/3"
            placeholder="Search organization name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                  handleSearch();
              }
          }}
          />
          <button  onClick={handleSearch} className="bg-primary text-white px-7 rounded-md">
            Search 
          </button>
        </div>
        <DataTable
          value={filteredOrganisations}
          emptyMessage={
            loading ? LoadingComponent : "No Organisations available"
          }
          onRowClick={onRowClick}
          rowClassName="bg-white hover:text-blue cursor-pointer"
        >
          <Column
            header=""
            headerClassName="w-24"
            body={loading ? circleSkeleton : renderOrgImage}
          />
          <Column
            sortable
            sortField="organizationID"
            body={loading ? contentSkeleton : renderOrgId}
            header="Id"
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base w-40"
          />
          <Column
            sortable
            sortField="name"
            body={loading ? contentSkeleton : renderOrgName}
            header="Name"
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base"
          />
          <Column
            header="Website"
            body={loading ? contentSkeleton : renderWebsite}
            className="text-sm 2xl:text-base w-1/3"
            headerClassName="text-sm 2xl:text-base"
          />
          <Column
            header="Hr's"
            sortable
            sortField="userCount"
            body={loading ? circleSkeleton : renderUserCount}
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base"
          />
          <Column
            sortable
            sortField="industry"
            body={loading ? contentSkeleton : renderIndustry}
            header="Industry"
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base"
          />
          {/* <Column 
                        field="hrTeam" 
                        header="HR team" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    /> */}
          <Column
            sortable
            sortField="status"
            body={loading ? contentSkeleton : renderStatus}
            header="Status"
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base bg-white"
          />
          {/* <Column 
                        field="noOfHiring" 
                        header="No. of hiring" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
          {/* <Column 
                        body={options}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
        </DataTable>
      </div>
      <Paginator
        rows={rows}
        first={first}
        totalRecords={totalCount}
        onPageChange={onPageChange}
        className={`${
          totalPage > 1 ? "visible" : "hidden"
        } justify-end bg-[#ECF5FA]`}
      />
    </div>
  );
}
