import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import ConfirmDialog from '../ConfirmDialog';
import { useLocation } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { MdClear, MdCloudUpload } from 'react-icons/md';
import CommonService from '../../services/common.service';
import ResumeService from '../../services/resume.service';
import React, { useState, useCallback, useRef } from 'react';
import { useToast } from '../../contextProviders/ToastContext';
import { extractResumeFilePath, uploadFile } from '../../utils/common';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export default function ResumesUploadPopup({ hide, getResumes }) {
  const {pathname} = useLocation();
  const uploadedFilesCount = useRef(0);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const {showSuccessMessage} = useToast();
  const [isUploading, setIsUploading] = useState(false);
  const jdId = pathname.replace('/dashboard/job-description/', '');
  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const updateProgress = () => {
    const progress = ((uploadedFilesCount.current / files.length) * 100).toFixed(0);
    setUploadingPercentage(progress);
  };

  const handleSumit = async () => {
    setError(null);
    setIsUploading(true);
    const presignUrls = [];
    setShowConfirmDialog(false);
    
    try {
      const uploadFiles = files?.map(async (file) => {
          const response = await CommonService.generatePresignUrlForResume(file.name.replace(/ /g, '-'));
          const url = response.data.data.url;
          presignUrls.push(url);
          uploadFile(url, file);
          uploadedFilesCount.current += 1;
          updateProgress();
      })

      await Promise.all(uploadFiles);
      const data = {
        jdId, 
        urls: presignUrls?.map((item) => extractResumeFilePath(item, '-'))
      }
      const response = await ResumeService.uploadResumes(data);

      if(response.status === 201){
        await new Promise(resolve => setTimeout(() => {
          setFiles([])
          hide();
          setIsUploading(false);
          showSuccessMessage({
            life: 5000,
            summary: 'Uploaded resumes', 
            detail: 'All resumes have been uploaded successfully.'
          });
          getResumes();
        }, 1000));
      } 

    } catch (err) {
      setError('Upload failed. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setError(null);

    // Filter out valid and oversized files
    
    const validFiles = acceptedFiles.filter(file => file.size <= MAX_FILE_SIZE);
    const oversizedFiles = acceptedFiles.filter(file => file.size > MAX_FILE_SIZE);
    
    if (oversizedFiles.length > 0)
        setError('Some files were rejected. Please only upload files under 5MB.');

    const files = [];
    validFiles?.forEach((file) => {
      const fileType = file.type;
      if(fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || fileType === "application/pdf"){
        files.push(file);
      }
    })
    setFiles((prevFiles) => [...prevFiles, ...files]);
  }, []);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      // 'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    multiple: true,
    preventDropOnDocument: true
  });
  
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleRemoveFile = (indexToRemove) => {
    if(isUploading) return;

    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
};

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center p-4 bg-black bg-opacity-50">
        {showConfirmDialog && (
            <ConfirmDialog
              accept={handleSumit} 
              cancel={() => setShowConfirmDialog(false)}   
              message={`You have uploaded ${files.length} files. Would you like to save them?`} 
            />
        )}        
        <div className="w-full max-w-md bg-white rounded-lg shadow overflow-hidden">
            {/* <div className={`${isUploading ? 'visible': 'hidden'}`}>
              <LoadingComponent />
            </div> */}
            <div className='p-6 flex flex-col gap-5'>
                <div className="flex justify-between items-center">
                    <h1 className="text-base font-semibold text-darkGray">Upload resume</h1>
                    <MdClear
                        size={20}
                        onClick={() => {
                            setFiles([]);
                            setError(null);
                            hide();
                        }}
                        className="text-brownGray cursor-pointer"
                    />
                </div>

                <div 
                    {...getRootProps()} 
                    className={`border-[1px] border-dashed rounded-lg p-3 cursor-pointer border-blue`}
                >
                    <input {...getInputProps()} />
                    <div className="text-center font-normal flex flex-col gap-4">
                      <MdCloudUpload className="mx-auto text-4xl text-primary" />
                      <p className="text-darkGray text-base">
                          {isDragActive
                          ? "Drop the files here ..."
                          : "Drag & drop files here, or click to select files"}
                      </p>
                      <span className='text-do font-normal text-sm'>
                        Support files format are .pdf, .docx and files up to 5MB
                      </span>
                    </div>
                </div>

                {error && <div className="mb-4 p-3 bg-lr text-dr text-sm font-normal rounded">{error}</div>}

                {files.length > 0 && (
                    <div className="font-m text-darkGray">
                      <h2 className="font-medium">Selected files: 
                        <span className='text-do ml-2 text-sm'>{files?.length}</span>
                        </h2>
                      <ul className="space-y-3 mt-4 overflow-y-auto max-h-60">
                          {files.map((file, index) => (
                            <li 
                              key={`${file?.name}-${index?.toString()}`} 
                              className="text-xs text-brownGray flex items-center gap-4 pr-2">
                                <p className="truncate flex-grow">{file.name}</p>
                                <MdClear
                                    size={18}
                                    onClick={handleRemoveFile.bind(this, index)}
                                    className="text-brownGray cursor-pointer"
                                />
                            </li>
                          ))}
                      </ul>
                    </div>
                )}

               {isUploading && (
                 <ProgressBar 
                    className='h-4'
                    value={uploadingPercentage}
                  />
               )}
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={() => {
                            setFiles([]);
                            setError(null);
                            hide();
                        }}
                        className="px-4 py-2 text-blue text-sm border-blue border-[1px] rounded-md"
                        disabled={isUploading}>
                        Cancel
                    </button>
                    <button
                        onClick={handleSumit}
                        disabled={files.length === 0 || isUploading}
                        className='px-4 py-2 bg-blue text-sm rounded-md text-white'>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
}

ResumesUploadPopup.propTypes = {
  resumes: PropTypes.array,
  setResumes: PropTypes.func,
  hide: PropTypes.func.isRequired
};