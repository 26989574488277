import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import Header from "../../../components/Common/Header";
import ConsoleHelper from "../../../utils/consoleHelper";
import { usePageData } from "../../../contextProviders/PagesDataContext";
import OrganizationService from "../../../services/organization.service";
import { useAuth } from "../../../contextProviders/AuthContext";
import {
  circleSkeleton,
  contentSkeleton,
} from "../../../components/Common/TableLoadingComponents";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import { InputText } from "primereact/inputtext";
import DashboardAnalyticsSection from "../../../components/Common/DashboardAnalyticsSection";
import { Paginator } from "primereact/paginator";

export default function AdminDashboard() {
  const navigate = useNavigate();
  const { updatePageData } = usePageData();

  const [loading, setLoading] = useState(false);
  const [selectedJD, setSelectedJD] = useState(null);
  const [organisations, setOrganisations] = useState([]);
  const [menuVisibility, setMenuVisibility] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  // const [first, setFirst] = useState(0);
  // const [rows, setRows] = useState(10);
  const { dateRange, changeAnalyticDateRange } = useAuth();

  useEffect(() => {
    updatePageData("jd-page", {});
    updatePageData("jd-resumes", {});
  }, []);

  const handleMenuVisibility = (value) => {
    if (value !== menuVisibility) setMenuVisibility(value);
  };

  const handleSelectedJd = (value) => {
    if (value !== selectedJD) setSelectedJD(value);
  };

  const onRowClick = ({ data }) => {
    navigate(`/dashboard/organizations/${data._id}/jds`);
  };

  useEffect(() => {
    async function getOrganizations() {
      try {
        setLoading(true);
        const response = await OrganizationService.getOrganizations(1, 20);
        setLoading(false);
        if (response.status === 200) {
          const { data } = response.data;
          setOrganisations(data);
        }
      } catch (error) {
        setLoading(false);
        ConsoleHelper.error(error?.response?.data?.message);
      }
    }
    getOrganizations();
  }, []);


  const filteredOrgs = organisations?.filter(org => 
    org?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase() || "")
) || [];

  // const onPageChange = (event) => {
  //   setFirst(event.first);
  //   setRows(event.rows);
  // };

  // const paginatedOrganisations = filteredOrganisations.slice(first, first + rows);


  const renderOrgImage = ({ logo }) => {
    return (
      <div>
        <img src={logo} alt="logo" className="h-10 w-10 rounded-full" />
      </div>
    );
  };
  const renderOrgId = ({ organizationID }) => {
    return (
      <div className="flex gap-4 items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {organizationID || "Not Available"}
        </span>
      </div>
    );
  };
  const renderOrgName = ({ name }) => {
    return (
      <div className="flex gap-4 items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {name || "Not Available"}
        </span>
      </div>
    );
  };

  const renderUserCount = ({ userCount }) => {
    return (
      <div className="h-8 w-8 flex items-center justify-center bg-primary rounded-full">
        <span className="text-white font-medium">{userCount}</span>
      </div>
    );
  };

  const renderWebsite = ({ website }) => {
    return (
      <div className="flex gap-4 items-center">
        {website ? (
          <span
            onClick={() => window.open(website, "_blank")}
            className="underline text-blue-500 cursor-pointer text-ellipsis overflow-hidden whitespace-nowrap"
            title={website}
          >
            {website}
          </span>
        ) : (
          <span className="text-gray-500">Not Available</span>
        )}
      </div>
    );
  };

  const renderIndustry = ({ industry }) => {
    return (
      <div className="flex gap-4 items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {industry || "Not Available"}
        </span>
      </div>
    );
  };
  const renderStatus = ({ status }) => {
    return (
      <div className="flex gap-4 items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {status || "Not Available"}
        </span>
      </div>
    );
  };

  return (
    <div
      onClick={handleSelectedJd.bind(this, null)}
      className="flex flex-col h-full gap-5 p-5"
    >
      <Header dateRange={dateRange} setDateRange={changeAnalyticDateRange} />
      <div className="w-full flex flex-col gap-10">
        <DashboardAnalyticsSection
          dateRange={dateRange}
          handleSelectedJd={handleSelectedJd}
          handleMenuVisibility={handleMenuVisibility}
        />

        <div className="flex flex-col gap-3">
          <h1 className="text-xl text-[#161616] font-bold">
            Organization List
          </h1>
          <div className="bg-white rounded">
            <div className="p-5 flex gap-5">
              <InputText
                className="h-8 border border-l_border px-2 text-sm w-1/3"
                placeholder="Search organization name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {/* <button onClick={handleSearch} className="bg-primary text-white px-7 rounded-md">
                Search
              </button> */}
            </div>
            <DataTable
              value={filteredOrgs}
              emptyMessage={loading ? LoadingComponent : "No Organisations available" }
              onRowClick={onRowClick}
              rowClassName="bg-white hover:text-blue cursor-pointer"
            >
              <Column
                header=""
                headerClassName="w-24"
                body={loading ? circleSkeleton : renderOrgImage}
              />
              <Column
                sortable
                sortField="organizationID"
                body={loading ? contentSkeleton : renderOrgId}
                header="Id"
                className="text-sm 2xl:text-base"
                headerClassName="text-sm 2xl:text-base w-40"
              />
              <Column
                sortable
                sortField="name"
                body={loading ? contentSkeleton : renderOrgName}
                header="Name"
                className="text-sm 2xl:text-base"
                headerClassName="text-sm 2xl:text-base"
              />
              <Column
                header="Website"
                body={loading ? contentSkeleton : renderWebsite}
                className="text-sm 2xl:text-base w-1/3"
                headerClassName="text-sm 2xl:text-base"
              />
              <Column
                sortable
                sortField="userCount"
                field="userCount"
                header="Hr's"
                body={loading ? circleSkeleton : renderUserCount}
                className="text-sm 2xl:text-base"
                headerClassName="text-sm 2xl:text-base"
              />
              <Column
                sortable
                sortField="industry"
                body={loading ? contentSkeleton : renderIndustry}
                header="Industry"
                className="text-sm 2xl:text-base"
                headerClassName="text-sm 2xl:text-base"
              />
              {/* <Column 
                                field="hrTeam" 
                                header="HR team" 
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base' 
                            /> */}
              <Column
                sortable
                sortField="status"
                body={loading ? contentSkeleton : renderStatus}
                field="status"
                header="Status"
                className="text-sm 2xl:text-base"
                headerClassName="text-sm 2xl:text-base bg-white"
              />
              {/* <Column 
                                field="noOfHiring" 
                                header="No. of hiring" 
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base bg-white' 
                            /> */}
              {/* <Column 
                                body={options}
                                className='text-sm 2xl:text-base'
                                headerClassName='text-sm 2xl:text-base bg-white' 
                            /> */}
            </DataTable>
            {/* <Paginator
              first={first}
              rows={rows}
              totalRecords={filteredOrgs?.length || 0}
              onPageChange={onPageChange}
              className="bg-[#ECF5FA] justify-end mt-5"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
