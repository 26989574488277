import PropTypes from 'prop-types';
import { useRef, useState } from "react";
import { Chip } from "primereact/chip";
import { Slider } from 'primereact/slider';
import { Editor } from "primereact/editor";
import {AiOutlineUpload} from 'react-icons/ai';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import JDService from "../../../services/jd.service";
import ConsoleHelper from '../../../utils/consoleHelper';
import { EMPLOYMENT_TYPES } from '../../../utils/constants';
import TextExtractor from "../../../utils/text-extractor";
import CommonService from "../../../services/common.service";
import { useToast } from "../../../contextProviders/ToastContext";
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import { convertToRange, getEditorHeaders } from "../../../utils/common";
import LoadingComponent from '../../../components/Common/LoadingComponent';

function JDGeneratePage(props){
    const {goToNextTab} = props;

    const { showErrorMessage } = useToast();
    const [loading, setLoading] = useState(false);
    const {getJDInfo, changeJDInfo, clearJD} = useJDInfo();

    const jdInfo = getJDInfo();
    const fileInputRef = useRef(null);
    const [error, setError] = useState({});
    const editorHeader = getEditorHeaders();

    const [uploading, setUploading] = useState(false);
    const [isGeneratedJd, setIsGeneratedJd] = useState(null);
    
    const [title, setTitle] = useState(jdInfo?.title || null);
    const [jdHtml, setJDHtml] = useState(jdInfo?.jdHtml || null);
    const [location, setLocation] = useState(jdInfo?.location || null);

    const initialExperience = jdInfo?.['experience'] ? convertToRange( jdInfo?.['experience']) : [2,5];
    const [experience, setExperience] = useState(initialExperience);

    const initialEmploymentType = 
        typeof jdInfo?.['employmentType'] === "string" ? { name: jdInfo?.['employmentType'], code: jdInfo?.['employmentType'] } :  
        typeof jdInfo?.['employmentType'] === "object" ? jdInfo?.['employmentType']  : {};

    const [employmentType, setEmploymentType] = useState(initialEmploymentType);

    const getSkills = (skills) => {
        if(!skills || skills?.length === 0) return [];

        if(typeof skills[0] === 'object')
            return skills?.map((item) => item?.name); 
        return skills;
    }

    const [primarySkillText, setPrimarySkillsText] = useState(jdInfo?.primarySkills ? getSkills(jdInfo?.primarySkills)?.join(",") : null);
    const [secondarySkillsText, setSecondarySkillsText] = useState(jdInfo?.secondarySkills ? getSkills(jdInfo?.secondarySkills)?.join(",") : null);

    const handleChanges = (key, value) => changeJDInfo({[key]: value})

    const handleFileChange = async event => {
        try {
            const file = event.target.files[0];
            if (!file) {
                showErrorMessage({ summary: 'File Required', detail: 'Please select a file'});
                return;
            }
    
            // Check if the file is either a PDF, DOC, or DOCX
            const isSupportedFile = 
                file?.name?.toLowerCase().endsWith('.pdf') ||
                file?.name?.toLowerCase().endsWith('.docx');
    
            if (!isSupportedFile) {
                showErrorMessage({ summary: 'Unsupported File Type', detail: 'Please upload the file in the docx or pdf format.'});
                return;
            }
    
            clearJD();
            resetValues();
            setUploading(true);
            setIsGeneratedJd(false);
            handleChanges('selectedOption', 'UPLOAD');
            setJDHtml(null);
            
            const result = await TextExtractor.extractText(file.type, file);
            const promises = [convertTextToHtml(result, file)];
            promises.push(extractJDInfo({job_description: result}));
            
            await Promise.all(promises);
            setUploading(false);
    
        } catch (error) {
            setUploading(false);
            ConsoleHelper.error(`error: ${JSON.stringify(error)}`);
        }
    };
    

    const validate = () => {
        setError({})
        const error = {};
        if(!title)
            error['title'] = 'Title required';
        if(!experience)
            error['experience'] = 'Experience required';
        if(!employmentType?.code)
            error['employmentType'] = 'Employment Type required';
        if(!primarySkillText || primarySkillText?.trim().length === 0)
            error['primarySkills'] = 'Mandatory Skills required';
        if(!secondarySkillsText || secondarySkillsText?.trim().length === 0)
            error['secondarySkills'] = 'Optional Skills required';
        if(Object.keys(error).length){
            setError(error)
            return false;
        }
        return true;
    }

    const generateJDSummary = async () => {
        try {
            if(!validate()) return;

            const primarySkills = primarySkillText.split(',').filter(word => word.trim());
            const secondarySkills = secondarySkillsText.split(',').filter(word => word.trim());

            const mandatorySkills = primarySkillText?.includes(",") ? primarySkills : [primarySkillText];
            const optionalSkills = secondarySkillsText?.includes(",") ? secondarySkills : [secondarySkillsText];

            // if(mandatorySkills?.length > 4 || optionalSkills?.length > 4){
            //     showErrorMessage({summary: 'Limit', detail: 'You can add upto 4 mandatory or optional skills'});
            //     return;
            // }

            setJDHtml(null);
            setUploading(true);
            setIsGeneratedJd(true);

            const requestBody = {
                jobTitle: title,
                location: location,
                primarySkills: mandatorySkills,
                secondarySkills: optionalSkills,
                employmentType: employmentType?.code,
                experience: `${experience[0]}-${experience[1]} years`,
            };

            const response = await JDService.generateJD(requestBody);
            setUploading(false);
            if(response.status === 200){
                const jdHtml = response.data.data?.replace('<!DOCTYPE html>', '');
                setJDHtml(jdHtml)
                changeJDInfo({ jdSelectedOption: 'GENERATE'})
            }
        }
        catch (error) {
            setUploading(false);
            showErrorMessage({ summary: 'Failed', detail: error?.response?.data?.message})
        }
    }

    const convertTextToHtml = async (jd, file) => {
        try {
            setIsGeneratedJd(false);
            const requestData = {text: jd}
            const response = await CommonService.getHtmlFromText(requestData);
            if(response.status === 200){
                const {data} = response.data;
                const parsedHtml = data?.replaceAll('```html\n', '').replaceAll('```', '');
                setJDHtml(parsedHtml);
                changeJDInfo({ uploadedJDFile: file, jdSelectedOption: 'UPLOAD'})
            } 
            else throw Error("failed to convert text to html")
        }
        catch (error) {
            const message = error?.response?.data?.message
            showErrorMessage({ summary: 'Failed', detail: message})
        }
    }

    const extractJDInfo = async (requestData) => {
        try {
            setIsGeneratedJd(false);
            const extractedJDInfo = await CommonService.extractBasicJdInfo(requestData);
            if(extractedJDInfo.status === 200){
                const jdInfo = extractedJDInfo?.data;
                const data = {};
                if(jdInfo.title && (!title || title?.trim()?.length === 0)){
                    setTitle(jdInfo.title)
                    data['title'] = jdInfo.title;
                }

                if(jdInfo?.minimumExperience && jdInfo?.maximumExperience){
                    setExperience([jdInfo?.minimumExperience, jdInfo?.maximumExperience]);
                    data['experience'] = [jdInfo?.minimumExperience, jdInfo?.maximumExperience];
                }
                else if(jdInfo?.minimumExperience){
                    setExperience([0, jdInfo?.minimumExperience]);
                    data['experience'] = [0, jdInfo?.minimumExperience];
                }
                else if(jdInfo?.maximumExperience){
                    setExperience([0, jdInfo?.maximumExperience]);
                    data['experience'] = [0, jdInfo?.maximumExperience];
                }

                if(jdInfo.location){
                    setLocation((jdInfo.location && jdInfo.location?.length) > 0 ? jdInfo?.location?.join(",") : "")
                    data['location'] = (jdInfo.location && jdInfo.location?.length) > 0 ? jdInfo?.location?.join(",") : ""
                }
                if(jdInfo.employmentType){
                    setEmploymentType({name: jdInfo.employmentType, code: jdInfo.employmentType})
                    data['employmentType'] = {name: jdInfo.employmentType, code: jdInfo.employmentType};
                }
                if(jdInfo.primarySkills){
                    const list = getSkills(jdInfo?.primarySkills)?.join(",");
                    setPrimarySkillsText(list)
                }
                if(jdInfo.secondarySkills){
                    const list = getSkills(jdInfo?.secondarySkills)?.join(",");
                    setSecondarySkillsText(list)
                }

                changeJDInfo(data)
            } 
        }
        catch (error) {
            setUploading(false);
            ConsoleHelper.error(JSON.stringify(error));
            const message = error?.response?.data?.message;
            showErrorMessage({ summary: 'Failed', detail: message })
        }
    }

    const valuesNotChanged = () => {
        return (
            JSON.stringify(jdHtml) === JSON.stringify(jdInfo['jdHtml']) &&
            JSON.stringify(primarySkillText) === JSON.stringify(jdInfo['primarySkillText'])  &&
            JSON.stringify(secondarySkillsText) === JSON.stringify(jdInfo['secondarySkillsText']) 
        )
    }

    const resetValues = () => {
        setTitle(null);
        setLocation(null);
        setExperience([2, 5]);
        setEmploymentType(null);
        setPrimarySkillsText(null);
        setSecondarySkillsText(null);
    }

    const onNext = async () => {
        try {
            if(!validate()) {
                showErrorMessage({summary: 'Required', detail: 'Some fields are missing'})
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return;
            }

            if(!jdHtml){
                showErrorMessage({summary: 'Required', detail: 'Either Generate or Upload JD'})
                return;
            }

            if(valuesNotChanged()){
                goToNextTab(1);
                return;
            }

            const primarySkills = primarySkillText.split(',').filter(word => word.trim());
            const secondarySkills = secondarySkillsText.split(',').filter(word => word.trim());

            const mandatorySkills = primarySkillText?.includes(",") ? primarySkills : [primarySkillText];
            const optionalSkills = secondarySkillsText?.includes(",") ? secondarySkills : [secondarySkillsText];

            // if(mandatorySkills?.length > 4 || optionalSkills?.length > 4){
            //     showErrorMessage({summary: 'Limit', detail: 'You can add upto 4 mandatory or optional skills'});
            //     return;
            // }

            // setLoading(true);

            // const requestData = {
            //     jd: jdHtml,
            //     primarySkills: mandatorySkills,
            //     secondarySkills: optionalSkills,
            // };

            // const response = await CommonService.evaluateJdSkills(requestData);
            // setLoading(false);
            // if(response.status === 200){
            //     const responseData = response.data['data'];
            //     const codingQuestions = response?.data?.['codingQuestions'] || [];

                
            // }
            const data = {
                title,
                location,
                experience,
                jdHtml: jdHtml,
                employmentType,
                primarySkillText,
                secondarySkillsText,
                primarySkills:  mandatorySkills,
                secondarySkills: optionalSkills
            };
            // if(codingQuestions && codingQuestions?.length > 0)
            //     data['technicalScreeningQAs'] = codingQuestions?.slice(0, 5) || [];

            changeJDInfo(data);
            goToNextTab();

        } catch (error) {
            setLoading(false);
            ConsoleHelper.log(`onNext ${JSON.stringify(error)}`)
        }

    }

    return (
        <div className="flex flex-col h-full gap-5">
            <div className={`${uploading || loading ? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-50 flex justify-center items-center`}>
                <div className="bg-white p-10 rounded-md w-1/4 flex justify-center items-center">
                    <LoadingComponent />
                </div>
            </div>
            <div className='flex gap-6 text-brownGray items-center justify-end'>
                <button 
                    onClick={generateJDSummary}
                    className='border w-32 text-center justify-center cursor-pointer text-blue px-2 h-9 rounded font-semibold text-xs flex items-center gap-4'>
                    Generate
                </button>
                <div
                    onClick={() => fileInputRef.current.click()} 
                    className="border-blue border cursor-pointer text-blue px-2 h-9 rounded font-semibold text-xs flex items-center gap-4 hover:bg-blue hover:text-white">
                    <span>Upload JD</span>
                    <AiOutlineUpload size={16}/>
                </div>
            </div>
            <input 
                type="file" 
                accept=".pdf, .docx"
                ref={fileInputRef} 
                className="hidden"
                onChange={handleFileChange} 
            />
            <div className="bg-white p-5 rounded-md">
                <div className="bg-white rounded gap-10 flex flex-col mt-2">
                    <div className="flex gap-5">
                        <div className="flex-col flex flex-1 gap-2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="title">Role / Job Title</label>
                            <InputText 
                                id="title"
                                value={title}
                                placeholder="Enter title" 
                                onChange={(e) => setTitle(e.target.value)}
                                className="border-l_border border-[1px] h-10 p-2"
                            />
                            {error['title'] && <span className="text-dr font-normal text-xs">{error['title']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label  
                                htmlFor="experience"
                                className="text-darkGray font-semibold text-sm">
                                Experience
                            </label>
                            {/* <Dropdown 
                                checkmark={true}
                                value={experience} 
                                optionLabel="name"
                                options={EXPERIENCES}  
                                defaultValue={experience}
                                highlightOnSelect={false}
                                placeholder="Select experience" 
                                className="h-10 border-l_border border-[1px]"
                                onChange={(e) => setExperience(e.value)}  
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            /> */}
                            <div className="flex flex-col gap-4 w-1/2">
                                <div className="flex justify-between">
                                    <div className="flex gap-2 items-center">
                                        <span>Min</span>
                                        <InputText
                                            min={0}
                                            max={50} 
                                            value={experience[0]}
                                            id="minExperience"
                                            contentEditable={false}
                                            className="border-l_border rounded-md text-sm border-[1px] text-center h-10 w-10"
                                        />
                                        <span>years</span>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <span>Max</span>
                                        <InputText
                                            min={0}
                                            max={50} 
                                            value={experience[1]}
                                            id="maxExperience"
                                            contentEditable={false}
                                            className="border-l_border rounded-md text-sm border-[1px] text-center h-10 w-10"
                                        />
                                        <span>years</span>
                                    </div>
                                </div>
                                <Slider
                                    range
                                    min={0} 
                                    max={30}
                                    value={experience} 
                                    className="w-14rem"
                                    onChange={(e) => {
                                        setExperience(e.value)
                                        handleChanges('experience', e.value) 
                                    } 
                                } />
                            </div>
                            {error['experience'] && <span className="text-dr font-normal text-xs">{error['experience']}</span>}
                        </div>
                    </div>
                    <div className="flex gap-5">
                        <div className="flex-col flex flex-1 gap-2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="location">Location</label>
                            <InputText 
                                id="location"
                                value={location}
                                placeholder="Add comma to add multiple locations" 
                                onChange={(e) => setLocation(e.target.value)}
                                className="border-l_border border-[1px] h-10 p-2"
                            />
                            {error['location'] && <span className="text-dr font-normal text-xs">{error['location']}</span>}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="employmentType">Employment Type</label>
                            <Dropdown
                                checkmark={true} 
                                optionLabel="name"  
                                value={employmentType}
                                highlightOnSelect={false}
                                options={EMPLOYMENT_TYPES}  
                                defaultValue={employmentType} 
                                onChange={(e) => setEmploymentType(e.value)} 
                                placeholder="Select employment type" 
                                className="h-10 border-l_border border-[1px] " 
                                pt={{
                                    input: {className: 'text-sm'},
                                    itemLabel: {className: 'text-sm'},
                                    item: {className: 'p-3'}
                                }} 
                            />
                            {error['employmentType'] && <span className="text-dr font-normal text-xs">{error['employmentType']}</span>}
                        </div>
                    </div>
                    <div className="flex gap-5 flex-col">
                        <div className="flex-col flex flex-1 gap-2">
                            <div className='flex gap-5 items-center'>
                                <label className="text-darkGray font-medium text-sm" htmlFor="primary-skills">Mandatory Skills</label>
                                {primarySkillText?.split(',').filter(word => word.trim())?.length > 4 && <span className='text-do text-sm'>Note: Interview will be conducted for first 4 skills </span>}
                            </div>
                            <InputText 
                                id="primary-skills"
                                value={primarySkillText}
                                placeholder="Add comma to seperate skills" 
                                className="border-l_border border-[1px] h-10 p-2"
                                onChange={(event) => {
                                    setPrimarySkillsText(event.target.value)
                                }}
                            />
                            {error['primarySkills'] && <span className="text-dr font-normal text-xs">{error['primarySkills']}</span>}
                            {primarySkillText && primarySkillText.split(',').filter(word => word.trim())?.length > 0 && (
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {primarySkillText.split(',').filter(word => word.trim()).map((item, index) => <Chip id={index} key={index} label={item}/>)}
                                </div>
                            )}
                        </div>
                        <div className="flex-1 flex flex-col gap-2">
                            <label className="text-darkGray font-medium text-sm" htmlFor="Add comma to seperate skills">Optional Skills</label>
                            <InputText 
                                id="secondary-skills"
                                value={secondarySkillsText}
                                placeholder="Enter Add comma to seperate skills"
                                className="border-l_border border-[1px] h-10 p-2"
                                onChange={(event) => {
                                    setSecondarySkillsText(event.target.value)
                                }}
                            />
                            {error['secondarySkills'] && <span className="text-dr font-normal text-xs">{error['secondarySkills']}</span>}
                            {secondarySkillsText && secondarySkillsText.split(',').filter(word => word.trim())?.length > 0 && (
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {secondarySkillsText.split(',').filter(word => word.trim()).map((item, index) => (
                                            <Chip id={index} key={index} label={item}/>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <button 
                        onClick={generateJDSummary}
                        className={`border w-40 border-blue rounded h-10 text-blue font-medium 
                            ${jdSelectedOption === 'UPLOAD' ? 'hidden': 'visible' }`}>
                        Generate
                    </button> */}
                </div>
            </div>
            <div className={`bg-white p-5 rounded-md ${jdHtml ? 'visible': 'hidden'}`}>
                <div className="pb-10">
                    <label className="text-darkGray font-bold text-xl" htmlFor="additional-details">
                        Job Description {isGeneratedJd === null ? '' : isGeneratedJd ? '| Generated JD' : '| Uploaded JD'}
                    </label>
                    <Editor 
                        value={jdHtml} 
                        className="mt-5 min-h-40"
                        headerTemplate={editorHeader}
                        onTextChange={(e) => setJDHtml(e.htmlValue)}
                    />
                </div>
            </div>
            <div className={`rounded py-5 gap-10 flex justify-end ${jdHtml ? 'visible': 'hidden'}`}>
                <button
                    onClick={onNext}
                    disabled={uploading}
                    className="bg-blue text-white h-10 rounded cursor-pointer font-semibold text-sm px-10">
                    Next
                </button>
            </div>
        </div>

    )
}

JDGeneratePage.propTypes = {
    setActiveTabIndex: PropTypes.func
}

export default JDGeneratePage;