import React from "react";
import { Skeleton } from 'primereact/skeleton';


export const contentSkeleton = () => {
    return  <Skeleton width="85%" height="1rem" />
};


export const circleSkeleton = () => {
    return <Skeleton shape="circle" size="30px" className="justify-self-center" />
};

export const SkeletonTable = ({ rows = 5, columns = 4 }) => {
    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <th key={colIndex} style={{ padding: "10px", textAlign: "left" }}>
                <Skeleton width="80%" height="1rem" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td key={colIndex} style={{ padding: "10px" }}>
                  <Skeleton width="100%" height="20px" />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };