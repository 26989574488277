import { useEffect, useState } from 'react';
import {BounceLoader} from 'react-spinners';
import { MdKeyboardArrowLeft } from "react-icons/md";
import ConsoleHelper from '../../utils/consoleHelper';
import { RESUME_STAGES } from '../../utils/constants';
import SessionService from '../../services/session.service';
import ReportOpsScreening from '../Report/ReportOpsScreening';
import ReportVideoProfiling from '../Report/ReportVideoProfiling';
import ReportBpoHrScreening from '../Report/ReportBpoHrScreening';
import ReportResumeScreening from '../Report/ReportResumeScreening';
import ReportInitialScreening from '../Report/ReportInitalScreening';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReportTechnicalScreening from '../Report/ReportTechnicalScreening';
import InterviewFeedbackPopup from '../../components/Common/InterviewFeedbackPopup';

export default function SessionPage(){
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location?.state;
    const goBack = () => navigate(-1);

    const [report, setReport] = useState({});
    const interviewType = state?.interviewType;

    const [loading, setLoading] = useState(false);
    const [screeningData, setScreeningData] = useState({});
    const [showInterviewFeedbackPopup, setShowInterviewFeedbackPopup] = useState(false); 


    const init = async () => {
        setLoading(true);
        try {
            const response = await SessionService.getSession(id, interviewType);
            setLoading(false);
            if (response.status === 200) {
                const data = response['data'];
                setReport(data?.['data'] || {});
            }
        } catch (error) {
            setLoading(false);
            ConsoleHelper.log(JSON.stringify(error));
        }
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (showInterviewFeedbackPopup) {
            let data = {};
            switch (report?.['screeningType']) {
                case RESUME_STAGES.INITIAL_SCREENING:
                    data = report?.initial_screening?.[0] || report;
                    break;
                case RESUME_STAGES.TECHNICAL_SCREENING:
                    data = report?.technical_screening?.[0] || report;
                    break;
                case RESUME_STAGES.OPS_SCREENING:
                    data = report?.ops_screening?.[0] || report;
                    break;
                case RESUME_STAGES.VIDEO_PROFILING:
                    data = report?.video_profiling?.[0] || report;
                    break;
                default:
                    data = report;
            }
            setScreeningData(data);
        }
    }, [showInterviewFeedbackPopup]);

    if(loading){
        return (
            <div className="justify-center items-center flex">
                <BounceLoader size={40} color="#2B4360"/>
            </div>
        )
    }
    
    return (
        <div className="h-screen p-5">
            <div className='flex flex-col gap-5 sticky top-0 z-40 bg-[#ECF5FA] pb-5'>
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex items-center gap-1 text-[#161616]">
                        <MdKeyboardArrowLeft
                            size={26} 
                            onClick={goBack}
                            className='cursor-pointer' 
                        />
                        <h1 className='text-xl text-primary font-bold'>Report</h1>
                    </div>
                </div>
                {(state?.jobTitle || report?.jobTitle) && (
                    <p className=' text-brownGray text-xl'>
                        Interview report of 
                        <span className='text-darkGray font-semibold px-2'>{state?.candidate?.['Name'] || report?.candidate?.['Name']}</span> for 
                        <span className='text-darkGray font-semibold px-2'>{state?.jobTitle || report?.jobTitle}</span>  role
                    </p>
                )}
            </div>
            <div className='bg-white p-5 pt-5 rounded-md text-sm font-normal text-primary'>
                {(report?.['screeningType'] === RESUME_STAGES.INITIAL_SCREENING || report?.['screeningType'] === RESUME_STAGES.INITIAL_SCREENING) && <ReportInitialScreening report={report} setShowInterviewFeedbackPopup= {setShowInterviewFeedbackPopup} />}
                {report?.['screeningType'] === RESUME_STAGES.TECHNICAL_SCREENING  && <ReportTechnicalScreening report={report} setShowInterviewFeedbackPopup= {setShowInterviewFeedbackPopup}/>}
                {report?.['screeningType'] === RESUME_STAGES.OPS_SCREENING  && <ReportOpsScreening report={report} setShowInterviewFeedbackPopup= {setShowInterviewFeedbackPopup} />}
                {report?.['screeningType'] === RESUME_STAGES.BPO_HR_SCREENING  && <ReportBpoHrScreening report={report} />}
                {report?.['screeningType'] === RESUME_STAGES.VIDEO_PROFILING  && <ReportVideoProfiling report={report} setShowInterviewFeedbackPopup= {setShowInterviewFeedbackPopup} />}
                {!report?.['screeningType']  && <ReportResumeScreening report={report} />}
            </div>

            {showInterviewFeedbackPopup && (
                <InterviewFeedbackPopup
                    sessionId={screeningData.id || screeningData._id}
                    hide={() => setShowInterviewFeedbackPopup(false)}
                    updateReport={init}
                />
            )}
        </div>
    )
}

