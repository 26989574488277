import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {BounceLoader} from 'react-spinners';
import { Skeleton } from 'primereact/skeleton';
import { RESUME_STAGES } from './../../utils/constants';
import CommonService from './../../services/common.service';
import { useAuth } from './../../contextProviders/AuthContext';
import applicantIcon from '././../../assets/icons/applicant.svg';
import { useConfig } from './../../contextProviders/ConfigContext';
import exitInterviewIcon from '././../../assets/icons/exit-interview.svg';
import initialInterviewIcon from '././../../assets/icons/initial-interview.svg';
import technicalInterviewIcon from '././../../assets/icons/technical-interview.svg';
import { usePageData } from './../../contextProviders/PagesDataContext';
import { useToast } from './../../contextProviders/ToastContext';
import SessionService from '../../services/session.service';
import { getDateRange } from '../../utils/common';

function DashboardAnalyticsSection(props){
    const config = useConfig();
    const {user} = useAuth();
    const {showErrorMessage} = useToast();
    const navigate = useNavigate();
    const interviewTypeRef = useRef(null);
    const {updatePageData, getPageData} = usePageData();
    const {dateRange, handleSelectedJd} = props;
    const [loading, setLoading] = useState(false);
    const [analytics, setAnalytics] = useState([]);
    const [isExtractingRecords, setIsExtractingRecords] = useState(false);


    const navigateTo = (path, data) => { 
        if(data?.['score'] === 0) return;
        updatePageData('sessions-page', {});

        navigate(path, {state: {...data, dateRange: dateRange}});
    }

    useEffect(() => {
        async function init(){
            setLoading(true);
            try {
                const response = await CommonService.getAnalytics(dateRange);
                if(response.status === 200){
                    setAnalytics(response.data);
                    updatePageData('analytics-page', response.data);
                }
                setLoading(false)
            } catch (error) {
                setLoading(false);
            }
        }
        init();
    }, [dateRange]);

    const extractRecords = async (interviewType, totalRecordCount) => {
        try {
            if(totalRecordCount <= 0){
                showErrorMessage({ life: 3000, summary: 'Failed', detail: 'No Records found' })
                return;
            }

            interviewTypeRef.current = interviewType? interviewType : 'Resumes';
            setIsExtractingRecords(true);
            const response = await SessionService.extractRecords(getDateRange   (dateRange), interviewType);
            setIsExtractingRecords(false)
            if(response.status === 200){
                const blob = await response.data;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${interviewType ? interviewType + '-' : 'Resumes-'}${Date.now()}.xlsx`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            setIsExtractingRecords(false);
            showErrorMessage({ life: 3000, summary: 'Failed', detail: 'Failed to extract records' })
        }
    }

    if(loading) {
        return (
            <div className="flex gap-5 w-full">
                {[1, 2, 3].map((item) => (
                    <div key={item} className="bg-white py-5 flex-1 px-4 gap-6 flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col gap-1">
                                <Skeleton width="100px" height="16px" />
                                <Skeleton width="80px" height="36px" className="mt-1" />
                            </div>
                            <Skeleton shape="circle" size="40px" />
                        </div>
                        <div className='flex items-center gap-2 mt-4'>
                            <Skeleton shape="circle" size="32px" />
                            <Skeleton width="100px" height="16px" />
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div 
            className="flex flex-wrap pb-5 gap-5"
            onClick={handleSelectedJd.bind(this, null)}>
            <div className='bg-white flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200'>
                <div 
                    className="flex flex-col gap-6 px-4 py-5">
                    <div className="flex items-center w-60 justify-between">
                        <div className="flex flex-col gap-1">
                            <span className='text-[#999999] font-normal text-sm'>Total applicants</span>
                            <h1 className='text-[#161616] text-2xl font-bold'>{analytics?.totalApplicants?.['TOTAL']}</h1>
                        </div>
                        <img alt='icon' src={applicantIcon} className='h-10 w-10'/>
                    </div>
                    <div className='flex gap-5'>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/resume-screening', {interviewType: RESUME_STAGES.RESUME_SCREENING, status: 'COMPLETED', score: analytics?.totalApplicants?.['COMPLETED'] || 0})}
                            className='flex items-center flex-col justify-center gap-1'>
                            <h3 className='text-[.6rem] font-bold text-primary'>RESUMES SHORTLISTED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalApplicants?.['COMPLETED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/resume-screening', {interviewType: RESUME_STAGES.RESUME_SCREENING, status: 'FAILED', score: analytics?.totalApplicants?.['FAILED'] || 0})}
                            className='flex items-start flex-col gap-1'>
                            <h3 className='text-[.6rem] font-bold text-primary'>FAILED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalApplicants?.['FAILED'] || 0}</span>
                        </div>
                    </div>
                </div>
                <button
                    disabled={isExtractingRecords}
                    onClick={extractRecords.bind(this, null, analytics?.totalApplicants?.['TOTAL'])}
                    className='bg-blue text-white h-9 text-sm flex justify-center items-center'>
                    {(isExtractingRecords && interviewTypeRef.current === 'Resumes') ?  <BounceLoader className='m-auto' size={26} color="#ffffff" /> : 'Extract Records'}
                </button>
            </div>
            <div className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.videoProfiling ? 'visible': 'hidden' }  bg-white flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className='flex flex-col gap-6 px-4 py-5'>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <span className='text-[#999999] font-normal text-sm'>Video Profiling</span>
                            <h1 className='text-[#161616] text-2xl font-bold'>{analytics?.totalVideoProfiling?.['TOTAL']}</h1>
                        </div>
                        <img alt='icon' src={exitInterviewIcon} className='h-10 w-10' />
                    </div>
                    <div className='flex gap-3 justify-between'>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.VIDEO_PROFILING, status: 'COMPLETED', score: analytics?.totalVideoProfiling?.['COMPLETED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>COMPLETED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalVideoProfiling?.['COMPLETED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.VIDEO_PROFILING, status: 'PENDING', score: analytics?.totalVideoProfiling?.['PENDING'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>INVITE SENT</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalVideoProfiling?.['PENDING'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.VIDEO_PROFILING, status: 'EXITED', score: analytics?.totalVideoProfiling?.['EXITED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>EXITED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalVideoProfiling?.['EXITED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {
                                interviewType: RESUME_STAGES.VIDEO_PROFILING, 
                                status: 'OTHERS', 
                                score: (analytics?.totalVideoProfiling?.['DISCONNECT'] || 0) + (analytics?.totalVideoProfiling?.['FAILED'] || 0) + (analytics?.totalVideoProfiling?.['ABNORMALLY_CLOSED'] || 0)
                            })}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>OTHERS</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>
                                {(analytics?.totalVideoProfiling?.['DISCONNECT'] || 0) + (analytics?.totalVideoProfiling?.['FAILED'] || 0) + (analytics?.totalVideoProfiling?.['ABNORMALLY_CLOSED'] || 0)}
                            </span>
                        </div>
                    </div>
                </div>
                <button
                    disabled={isExtractingRecords}
                    onClick={extractRecords.bind(this, RESUME_STAGES.VIDEO_PROFILING, analytics?.totalVideoProfiling?.['TOTAL'])}
                    className='bg-blue text-white h-9 text-sm flex justify-center items-center'>
                    {(isExtractingRecords && interviewTypeRef.current === RESUME_STAGES.VIDEO_PROFILING) ?  <BounceLoader className='m-auto' size={26} color="#ffffff" /> : 'Extract Records'}
                </button>
            </div>
            <div className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.hrScreening ? 'visible': 'hidden' }  bg-white flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className='flex flex-col gap-6 px-4 py-5'>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <span className='text-[#999999] font-normal text-sm'>Initial HR Interview</span>
                            <h1 className='text-[#161616] text-2xl font-bold'>{analytics?.totalHRInterviews?.['TOTAL']}</h1>
                        </div>
                        <img alt='icon' src={initialInterviewIcon}className='h-10 w-10'
                        />
                    </div>
                    <div className='flex gap-3 justify-between'>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.INITIAL_SCREENING, status: 'COMPLETED', score: analytics?.totalHRInterviews?.['COMPLETED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>COMPLETED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalHRInterviews?.['COMPLETED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.INITIAL_SCREENING, status: 'PENDING', score: analytics?.totalHRInterviews?.['PENDING'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>INVITE SENT</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalHRInterviews?.['PENDING'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.INITIAL_SCREENING, status: 'EXITED', score: analytics?.totalHRInterviews?.['EXITED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>EXITED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalHRInterviews?.['EXITED'] || 0}</span>
                        </div>
                        <div
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {
                                status: 'OTHERS', 
                                interviewType: RESUME_STAGES.INITIAL_SCREENING, 
                                score: (analytics?.totalHRInterviews?.['DISCONNECT'] || 0) + (analytics?.totalHRInterviews?.['FAILED'] || 0) + (analytics?.totalHRInterviews?.['ABNORMALLY_CLOSED'] || 0)
                            })}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>OTHERS</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>
                                {(analytics?.totalHRInterviews?.['DISCONNECT'] || 0) + (analytics?.totalHRInterviews?.['FAILED'] || 0) + (analytics?.totalHRInterviews?.['ABNORMALLY_CLOSED'] || 0)}
                            </span>
                        </div>
                    </div>
                </div>
                <button
                    disabled={isExtractingRecords}
                    onClick={extractRecords.bind(this, RESUME_STAGES.INITIAL_SCREENING, analytics?.totalHRInterviews?.['TOTAL'])}
                    className='bg-blue text-white h-9 text-sm flex justify-center items-center'>
                    {(isExtractingRecords && interviewTypeRef.current === RESUME_STAGES.INITIAL_SCREENING) ?  <BounceLoader className='m-auto' size={26} color="#ffffff" /> : 'Extract Records'}
                </button>
            </div>
            <div className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.technicalScreening ? 'visible': 'hidden' }  bg-white flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className='flex flex-col gap-6 px-4 py-5'>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <span className='text-[#999999] font-normal text-sm'>Technical Interview</span>
                            <h1 className='text-[#161616] text-2xl font-bold'>{analytics?.totalTechnicalInterviews?.['TOTAL']}</h1>
                        </div>
                        <img alt='icon' src={technicalInterviewIcon} className='h-10 w-10'/>
                    </div>
                    <div className='flex gap-3 justify-between'>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.TECHNICAL_SCREENING, status: 'COMPLETED', score: analytics?.totalTechnicalInterviews?.['COMPLETED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>COMPLETED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalTechnicalInterviews?.['COMPLETED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.TECHNICAL_SCREENING, status: 'PENDING', score: analytics?.totalTechnicalInterviews?.['PENDING'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>INVITE SENT</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalTechnicalInterviews?.['PENDING'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.TECHNICAL_SCREENING, status: 'EXITED', score: analytics?.totalTechnicalInterviews?.['EXITED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>EXITED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalTechnicalInterviews?.['EXITED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {
                                status: 'OTHERS', 
                                interviewType: RESUME_STAGES.TECHNICAL_SCREENING, 
                                score: (analytics?.totalTechnicalInterviews?.['DISCONNECT'] || 0) + (analytics?.totalTechnicalInterviews?.['FAILED'] || 0) + (analytics?.totalTechnicalInterviews?.['ABNORMALLY_CLOSED'] || 0)
                            })}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>OTHERS</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>
                                {(analytics?.totalTechnicalInterviews?.['DISCONNECT'] || 0) + (analytics?.totalTechnicalInterviews?.['FAILED'] || 0) + (analytics?.totalTechnicalInterviews?.['ABNORMALLY_CLOSED'] || 0)}
                            </span>
                        </div>
                    </div>
                </div>
                <button
                    disabled={isExtractingRecords}
                    className='bg-blue text-white h-9 text-sm flex justify-center items-center'
                    onClick={extractRecords.bind(this, RESUME_STAGES.TECHNICAL_SCREENING, analytics?.totalTechnicalInterviews?.['TOTAL'])}>
                    {(isExtractingRecords && interviewTypeRef.current === RESUME_STAGES.TECHNICAL_SCREENING) ?  <BounceLoader className='m-auto' size={26} color="#ffffff" /> : 'Extract Records'}
                </button>
            </div>
            <div className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.bpoHrScreening ? 'visible': 'hidden' }  bg-white flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div 
                    className='flex flex-col gap-6 px-4 py-5'>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <span className='text-[#999999] font-normal text-sm'>BPO HR Interview</span>
                            <h1 className='text-[#161616] text-2xl font-bold'>{analytics?.totalBpoHRInterviews?.['TOTAL']}</h1>
                        </div>
                        <img alt='icon' src={exitInterviewIcon} className='h-10 w-10' />
                    </div>
                    <div className='flex gap-3 justify-between'>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.BPO_HR_SCREENING, status: 'COMPLETED', score: analytics?.totalBpoHRInterviews?.['COMPLETED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>COMPLETED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalBpoHRInterviews?.['COMPLETED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.BPO_HR_SCREENING, status: 'PENDING', score: analytics?.totalBpoHRInterviews?.['PENDING'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>INVITE SENT</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalBpoHRInterviews?.['PENDING'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.BPO_HR_SCREENING, status: 'EXITED', score: analytics?.totalBpoHRInterviews?.['EXITED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>EXITED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalBpoHRInterviews?.['EXITED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {
                                status: 'OTHERS', 
                                interviewType: RESUME_STAGES.BPO_HR_SCREENING, 
                                score: (analytics?.totalBpoHRInterviews?.['DISCONNECT'] || 0) + (analytics?.totalBpoHRInterviews?.['FAILED'] || 0) + (analytics?.totalBpoHRInterviews?.['ABNORMALLY_CLOSED'] || 0)
                            })}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>OTHERS</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>
                                {(analytics?.totalBpoHRInterviews?.['DISCONNECT'] || 0) + (analytics?.totalBpoHRInterviews?.['FAILED'] || 0) + (analytics?.totalBpoHRInterviews?.['ABNORMALLY_CLOSED'] || 0)}
                            </span>
                        </div>
                    </div>
                </div>
                <button
                    disabled={isExtractingRecords}
                    onClick={extractRecords.bind(this, RESUME_STAGES.BPO_HR_SCREENING, analytics?.totalBpoHRInterviews?.['TOTAL'])}
                    className='bg-blue text-white h-9 text-sm flex justify-center items-center'>
                    {(isExtractingRecords && interviewTypeRef.current === RESUME_STAGES.BPO_HR_SCREENING) ?  <BounceLoader className='m-auto' size={26} color="#ffffff" /> : 'Extract Records'}
                </button>
            </div>
            <div className={`${(user && user?.['role'] === 'admin') || config?.enabledServices?.opsScreening ? 'visible': 'hidden' }  bg-white flex flex-col rounded-md shadow hover:scale-105 cursor-pointer transition-transform duration-200`}>
                <div className='flex flex-col gap-6 px-4 py-5'>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <span className='text-[#999999] font-normal text-sm'>OPS Interview</span>
                            <h1 className='text-[#161616] text-2xl font-bold'>{analytics?.totalOpsInterviews?.['TOTAL']}</h1>
                        </div>
                        <img alt='icon' src={exitInterviewIcon} className='h-10 w-10' />
                    </div>
                    <div className='flex gap-3 justify-between'>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.OPS_SCREENING, status: 'COMPLETED', score: analytics?.totalOpsInterviews?.['COMPLETED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>COMPLETED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalOpsInterviews?.['COMPLETED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.OPS_SCREENING, status: 'PENDING', score: analytics?.totalOpsInterviews?.['PENDING'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>INVITE SENT</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalOpsInterviews?.['PENDING'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {interviewType: RESUME_STAGES.OPS_SCREENING, status: 'EXITED', score: analytics?.totalOpsInterviews?.['EXITED'] || 0})}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>EXITED</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>{analytics?.totalOpsInterviews?.['EXITED'] || 0}</span>
                        </div>
                        <div 
                            onClick={navigateTo.bind(this, '/dashboard/sessions', {
                                status: 'OTHERS', 
                                interviewType: RESUME_STAGES.OPS_SCREENING, 
                                score: (analytics?.totalOpsInterviews?.['DISCONNECT'] || 0) + (analytics?.totalOpsInterviews?.['FAILED'] || 0) + (analytics?.totalOpsInterviews?.['ABNORMALLY_CLOSED'] || 0)
                            })}
                            className='flex flex-col gap-1 items-center'>
                            <h3 className='text-[.6rem] font-bold text-primary'>OTHERS</h3>
                            <span className='text-xs font-semibold text-center items-center flex justify-center h-8 w-8 bg-primary text-white rounded-full'>
                                {(analytics?.totalOpsInterviews?.['DISCONNECT'] || 0) + (analytics?.totalOpsInterviews?.['FAILED'] || 0) + (analytics?.totalOpsInterviews?.['ABNORMALLY_CLOSED'] || 0)}
                            </span>
                        </div>
                    </div>
                </div>
                <button
                    disabled={isExtractingRecords}
                    onClick={extractRecords.bind(this, RESUME_STAGES.OPS_SCREENING, analytics?.totalOpsInterviews?.['TOTAL'])}
                    className='bg-blue text-white h-9 text-sm flex justify-center items-center'>
                    {(isExtractingRecords && interviewTypeRef.current === RESUME_STAGES.OPS_SCREENING) ?  <BounceLoader className='m-auto' size={26} color="#ffffff" /> : 'Extract Records'}
                </button>
            </div>
        </div>      
    )
}

DashboardAnalyticsSection.propTypes = {
    dateRange: PropTypes.string,
    handleSelectedJd: PropTypes.func,
    handleMenuVisibility: PropTypes.func
}

export default DashboardAnalyticsSection;