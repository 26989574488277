import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import { MdClear } from "react-icons/md";
import { useToast } from "../../contextProviders/ToastContext";

export default function ResumeActionPopup({ hide, actionType, handleAction }) {
  const [reason, setReason] = useState("");
  const { showErrorMessage } = useToast();

  const handleSubmit = () => {
    if (!reason.trim()) {
      showErrorMessage("Reason is required");
      return;
    }
    try {
      handleAction(reason);
      hide();
    } catch (error) {
      console.error(`Failed to ${actionType.toLowerCase()} resumes:`, error);
    }
  };

  // Dynamic text based on action type
  const actionText = {
    REJECTED: "Reject Resumes",
    "ON HOLD": "Put Resumes on Hold",
    SELECTED: "Select Resumes",
  };

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center p-4 bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="w-full max-w-lg bg-white rounded-lg overflow-hidden transform transition-all duration-200 ease-in-out">
        <div className="p-6 flex flex-col gap-5">
          {/* Header */}
          <div className="flex justify-between items-center">
            <h1 className="font-semibold text-xl text-gray-800">
              {actionText[actionType]}
            </h1>
            <MdClear
              size={20}
              className="text-gray-500 hover:text-gray-700 cursor-pointer transition-colors duration-200"
              onClick={hide}
            />
          </div>

          {/* Reason Textarea */}
          <InputTextarea
            placeholder="Enter reason..."
            className="w-full p-3 border border-blue rounded-lg focus:border-blue focus:ring-2 focus:ring-blue transition-all duration-200"
            rows={6}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            autoFocus
          />

          {/* Submit Button */}
          <div className="flex justify-center">
            <button
              className={`px-4 py-2 text-sm rounded-md bg-blue text-white
              ${!reason ? "opacity-50 cursor-not-allowed" : "opacity-100"}`}
              onClick={handleSubmit}
              disabled={!reason}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}