
import { Helmet } from 'react-helmet-async';
import HeroSection from "../components/Product/HeroSection";
import FeaturesSection from "../components/Product/FeaturesSection";
import ProductInfoSection from "../components/Product/ProductInfoSection";
import FeatureCardsSection from "../components/Landing/FeatureCardsSection";
import ResumeScreeningSection from "../components/Product/ResumeScreeningSection";

export default function ProductsPage(){
    
    return (
        <div className="w-full overflow-hidden">
            <Helmet>
                <title>Products | Gen AI Interviewer </title>
                <meta name="description" content="Athmick AI offers a comprehensive AI-powered platform that automates job descriptions, candidate assessments, and interview scheduling, ensuring seamless, unbiased, and efficient hiring." />
                <meta name="keywords" content="AI recruitment platform, automated hiring tools, job description automation, candidate assessments, interview scheduling, bias-free hiring, AI-driven evaluations, recruitment technology, talent acquisition solutions, hiring efficiency" />
            </Helmet>
            <HeroSection />
            <FeaturesSection />
            <ResumeScreeningSection />
            <ProductInfoSection />
            <FeatureCardsSection />
        </div>
    )
}