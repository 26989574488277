
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function DaysRangeDropdown(props){
    const menu = useRef(null);
    const label = props?.label || 'Year';
    const [menuVisibility, setMenuVisibility] = useState(false);

    const menus = [
        {
            label: "Last 24 hours",
            command: () => {
                props?.setLabel('Last 24 hours');
                setMenuVisibility(false);
            }
        },
        {
            label: "Last 7 days",
            command: () => {
                props?.setLabel('Last 7 days');
                setMenuVisibility(false);
            }
        },
        {
            label: "Last 15 days",
            command: () => {
              props?.setLabel('Last 15 days');
              setMenuVisibility(false);
            }
        },
        {
            label: "Last 1 month",
            command: () => {
                props?.setLabel('Last 1 month');
                setMenuVisibility(false);
            }
        },
        {
            label: "Last 3 month",
            command: () => {
                props?.setLabel('Last 3 month');
                setMenuVisibility(false);
            }
        },
        {
            label: "Last 6 month",
            command: () => {
                props?.setLabel('Last 6 month');
                setMenuVisibility(false);
            }
        },
        {
            label: "Last 1 year",
            command: () => {
                props?.setLabel('Last 1 year');
                setMenuVisibility(false);
            }
        },
    ]

    const onClick = (event) => {                       
            if(menuVisibility) menu.current.hide(event)
            else menu.current.show(event)
            setMenuVisibility(!menuVisibility)
    }

    return (
        <div 
            onClick={onClick} 
            className="bg-white text-blue flex px-3 py-2 gap-5 justify-between items-center rounded cursor-pointer relative w-42 border-2 border-blue">
            <h4 className="text-sm">{label}</h4>
            {!menuVisibility && <MdOutlineKeyboardArrowDown size={22} /> }
            {menuVisibility && <MdOutlineKeyboardArrowUp size={22} /> }
            <Menu 
                popup 
                ref={menu}
                className="w-40"
                model={menus}
            />
        </div>
    )
}