import moment from "moment/moment";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import logo from "./../../../assets/icons/logo.svg";
import JDService from "../../../services/jd.service";
import { BsThreeDotsVertical } from "react-icons/bs";
import addIcon from "./../../../assets/icons/add.svg";
import editIcon from "./../../../assets/icons/edit.svg";
import { FaToggleOff, FaToggleOn } from "react-icons/fa6";
import ConsoleHelper from "../../../utils/consoleHelper";
import { useToast } from "../../../contextProviders/ToastContext";
import { useAuth } from "../../../contextProviders/AuthContext";
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import LoadingComponent from "../../../components/Common/LoadingComponent";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/common";
import CustomPaginator from "../../../components/Common/CustomPaginator";
import { usePageData } from "../../../contextProviders/PagesDataContext";
import {
  circleSkeleton,
  contentSkeleton,
} from "../../../components/Common/TableLoadingComponents";
import { InputText } from "primereact/inputtext";

export default function JDPage() {
  const { user } = useAuth();

  const { clearJD } = useJDInfo();
  const { updatePageData, getPageData } = usePageData();

  const navigate = useNavigate();
  const jdPage = getPageData("jd-page") || {};

  const [loading, setLoading] = useState(false);

  const [clickedOn, setClickedOn] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [selectedJD, setSelectedJD] = useState(null);
  const [JDs, setJDs] = useState(jdPage?.["jds"] || []);
  const { showErrorMessage, showSuccessMessage } = useToast();
  const [sort, setSort] = useState(jdPage["sort"]);
  const [sortField, setSortField] = useState(jdPage["sortField"]);
  const [totalCount, setTotalCount] = useState(jdPage?.["totalCount"] || 0);
  const [totalPage, setTotalPage] = useState(jdPage?.["totalPage"] || 0);
  const [currentPage, setCurrentPage] = useState(jdPage?.["currentPage"] || 1);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [search, setSearch] = useState(null);

  const [limit, setLimit] = useState(jdPage?.["limit"] || 10);

  useEffect(() => {
    clearJD();

    const handleResize = () => {
      setLimit(10);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function getJDs(sort, sortField) {
    try {
      setLoading(true);
      const response = await JDService.getJds(
        currentPage,
        limit,
        sort,
        sortField,
        search
      );
      setLoading(false);
      if (response.status === 200) {
        const { data, totalCount, totalPage } = response.data;
        setJDs(data);
        setTotalCount(totalCount);
        setTotalPage(totalPage);
      }
    } catch (error) {
      setLoading(false);
      ConsoleHelper.error(error?.response?.data?.message);
    }
  }

  const searchJDs = async () => {
    try {
      setLoading(true);
      const response = await JDService.getJds(
        currentPage,
        limit,
        sort,
        sortField,
        search
      );
      setLoading(false);
      if (response.status === 200) {
        const { data, totalCount, totalPage } = response.data || [];

        const list = data?.map((item) => ({ ...item, selected: false }));
        setJDs(list);
        setTotalCount(totalCount);
        setTotalPage(totalPage);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJDs(sort, sortField);
  }, [currentPage, limit]);

  const onEditClick = () => {
    clearJD();
    navigate(`/dashboard/job-description/${selectedJD._id}/edit`);
  };

  const inActivateJDClick = () => {
    setClickedOn("inactivate");
    setShowConfirmationDialog(true);
  };

  const activateJDClick = () => {
    setClickedOn("activate");
    setShowConfirmationDialog(true);
  };

  const toggleJDActiveStatus = async () => {
    try {
      setUploading(true);
      const jdResponse =
        clickedOn === "activate"
          ? await JDService.activateJD(selectedJD?._id)
          : await JDService.inactivateJD(selectedJD?._id);
      setUploading(false);
      if (jdResponse.status === 200) {
        setShowConfirmationDialog(false);
        setJDs(
          JDs?.map((item) => {
            if (item?._id === selectedJD?._id)
              return {
                ...item,
                active: clickedOn === "activate" ? true : false,
              };
            return item;
          })
        );
        setSelectedJD(null);
        if (clickedOn === "inactivate") {
          showSuccessMessage({
            life: 5000,
            summary: "Alert",
            detail:
              "Please ensure there are no active interview invites. Candidate can continue the interview if any invites are sent",
          });
        } else {
          showSuccessMessage({
            summary: "Success",
            detail: "JD is activated",
          });
        }
      }
    } catch (error) {
      setUploading(false);
      setShowConfirmationDialog(false);
      showErrorMessage({
        summary: "Failed",
        detail: error?.response?.data?.message,
      });
    }
  };

  const renderLocation = ({ location }) => {
    return (
      <div className="flex gap-4 text-sm items-center w-32">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {!location || location?.length === 0 || !location[0]
            ? "-"
            : location
                ?.map((item) => capitalizeFirstLetterOfEachWord(item))
                ?.join(",")}
        </span>
      </div>
    );
  };

  const renderUploadedBy = ({ userDetails }) => {
    return (
      <div className="flex gap-4 text-sm items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {!userDetails
            ? "-"
            : user?.["_id"] === userDetails?.["_id"]
            ? "Self"
            : userDetails?.["firstName"]}
        </span>
      </div>
    );
  };

  const resumeCount = ({ resumeCount }) => {
    return (
      <div className="justify-center items-center flex">
        <div className="h-8 w-8 rounded-full bg-primary justify-center items-center flex">
          <span className="text-white font-medium text-xs">
            {resumeCount || 0}
          </span>
        </div>
      </div>
    );
  };

  const renderCreatedAt = ({ createdAt }) => {
    return (
      <div className="items-center flex">
        <span className="w-40 text-sm">
          {moment(createdAt).format("DD MMM yyyy")}
        </span>
      </div>
    );
  };

  const renderJobTitle = (item) => {
    return (
      <div className="flex gap-4 text-sm items-center w-40">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {item?.title}
        </span>
      </div>
    );
  };

  const renderPrimarySkills = ({ primarySkills }) => {
    const list = primarySkills[0]?.name
      ? primarySkills?.map((item) => item?.name)
      : primarySkills;
    return (
      <div className="flex gap-4 text-sm items-center w-32">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {list?.join(", ")}
        </span>
      </div>
    );
  };

  const renderHrName = (item) => {
    return (
      <div className="flex gap-4 text-sm items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {item?.userDetails?.firstName}
        </span>
      </div>
    );
  };
  const renderOrganizationName = (item) => {
    return (
      <div className="flex gap-4 text-sm items-center">
        <span className="text-ellipsis overflow-hidden whitespace-nowrap">
          {item?.organizationDetails?.name}
        </span>
      </div>
    );
  };

    const options = (item) => {
        if(user['role'] !== 'hrAdmin' && item?.['user'] !== user['_id']) return;

        return (
            <div 
                onClick={(event) => event?.stopPropagation()}
                className={`absolute transform -translate-x-1/2 -translate-y-1/2`}>
                <BsThreeDotsVertical
                    size={20} 
                    color='#666666'
                    className="cursor-pointer"
                    onClick={() => setSelectedJD(item)}
                />
                <div className={`${selectedJD?._id === item?._id ? 'visible' : 'hidden'} rounded-lg shadow shadow-gray bg-white -top-5 right-0 z-20 absolute`}>
                    <ul className="p-2 gap-2 flex flex-col">
                        <li
                            style={{backgroundColor: 'rgba(60, 153, 202, .1)'}}
                            onClick={onEditClick.bind(this, item)} 
                            className={`py-2 text-blue flex items-center gap-3 px-3 rounded cursor-pointer ${item?.active === false ? 'hidden': 'visible'}`}>
                            <img 
                                alt='edit-icon'
                                src={editIcon}
                                className='h-4 w-4'
                            />
                            <h4 className='text-sm'>Edit</h4>
                        </li>
                        <li 
                            onClick={inActivateJDClick}
                            className={`py-2 text-[#FF3B30] flex items-center gap-3 px-3 justify-between rounded cursor-pointer ${item?.active !== false ? 'visible': 'hidden'}`} 
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <FaToggleOff size={20}/>
                            <h4 className='text-sm bg'>Inactive</h4>
                        </li>
                        <li 
                            onClick={activateJDClick}
                            className={`py-2 text-[#FF3B30] flex items-center gap-3 px-3 justify-between rounded cursor-pointer ${item?.active === false ? 'visible': 'hidden'}`}
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <FaToggleOn size={20} />
                            <h4 className='text-sm'>Active</h4>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

  return (
    <div
      onClick={() => setSelectedJD(null)}
      className="flex flex-col h-full gap-4 p-5"
    >
      <div
        className={`${
          showConfirmationDialog ? "visible" : "hidden"
        } absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}
      >
        <div className="bg-white p-5 rounded-md flex justify-center items-center">
          {uploading && <LoadingComponent />}
          <div
            className={`${
              uploading ? "hidden" : "visible"
            } flex flex-col justify-center items-center gap-5`}
          >
            <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex">
              <img src={logo} className="h-11 w-11" alt="logo" />
            </div>
            <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
            <div className="flex flex-col items-center justify-center">
              <p className="text-brownGray text-base">
                Would you want to{" "}
                {clickedOn === "activate" ? "activate" : "inactivate"} the JD?
              </p>
            </div>
            <div className="flex items-center justify-center gap-10 py-5 text-sm">
              <button
                className="h-10 border border-primary w-32 font-medium"
                onClick={() => setShowConfirmationDialog(false)}
              >
                NO
              </button>
              <button
                className="bg-primary h-10 rounded w-32 text-white font-medium"
                onClick={toggleJDActiveStatus}
              >
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <h1 className="text-[#161616] text-xl font-bold">
          Job description list
        </h1>
        {user && user["role"] !== "admin" && (
          <div
            onClick={() => navigate("/dashboard/job-description/create")}
            className="bg-blue cursor-pointer text-white px-4 h-10 rounded font-semibold text-sm flex items-center gap-4"
          >
            <span>Create JD</span>
            <img alt="add" src={addIcon} className="h-5 w-5" />
          </div>
        )}
      </div>
      {/* <div className='flex gap-4'>
                <SearchbarComponent 
                    label={search}
                    onChange={setSearch}              
                />
                <ExperienceDropdownComponent 
                    label={selectedExperience}
                    setLabel={setSelectedExperience}
                />
                <LocationDropdownComponent 
                    label={selectedLocation}
                    setLabel={setSelectedLocation}
                />
            </div> */}
      <div className="bg-white rounded mt-5">
        <div className="p-5 flex gap-5">
          <InputText
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className="h-8 border border-l_border px-2 text-sm w-1/3"
            placeholder="Search job title"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                searchJDs();
              }
          }}
          />
          <button
            onClick={searchJDs}
            className="bg-primary text-white px-7 rounded-md"
          >
            Search
          </button>
        </div>
        <DataTable
          value={JDs}
          onRowClick={({ data }) => {
            const props = {
              limit,
              jds: JDs,
              totalPage,
              totalCount,
              currentPage,
              sort,
              sortField,
            };
            updatePageData("jd-page", props);
            navigate(`/dashboard/job-description/${data._id}`, { state: data });
          }}
          onSort={(props) => {
            let sortValue = sort === "asc" ? "desc" : "asc";
            setSort(sortValue);
            setSortField(props.sortField);
            getJDs(sortValue, props.sortField);
          }}
          emptyMessage={loading ? <LoadingComponent /> : "No Jd available"}
          rowClassName="bg-white hover:text-blue hover:cursor-pointer"
        >
          {user && user?.["role"] === "admin" && (
            <Column
              sortable
              header="Organization"
              sortField="organizationDetails.name"
              // field="organizationDetails.name"
              body={loading ? contentSkeleton() : renderOrganizationName}
              className="text-sm 2xl:text-base"
              headerClassName="text-sm 2xl:text-base bg-white"
            />
          )}
          {user && user?.["role"] === "admin" && (
            <Column
              sortable
              header="Hr Name"
              sortField="userDetails.firstName"
              body={loading ? contentSkeleton() : renderHrName}
              className="text-sm 2xl:text-base"
              headerClassName="text-sm 2xl:text-base bg-white"
            />
          )}
          <Column
            sortable
            field="title"
            header="Title"
            body={loading ? contentSkeleton() : renderJobTitle}
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base bg-white"
          />
          {/* <Column 
                        field="experience" 
                        header="Experience" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
          <Column
            field="primarySkills"
            header="Mandatory Skills"
            body={loading ? contentSkeleton() : renderPrimarySkills}
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base bg-white"
          />
          {/* <Column 
                        field="secondarySkills" 
                        header="Optional Skills" 
                        body={renderSecondarySkills} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white bg-white' 
                    /> */}
          <Column
            sortable
            sortField="resumeCount"
            header="Resume Count"
            body={loading ? circleSkeleton() : resumeCount}
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base bg-white"
          />
          <Column
            header="Location"
            body={loading ? contentSkeleton() : renderLocation}
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base bg-white"
          />
          {user?.["role"] === "hrAdmin" && (
            <Column
              header="Uploaded By"
              body={loading ? contentSkeleton() : renderUploadedBy}
              className="text-sm 2xl:text-base"
              headerClassName="text-sm 2xl:text-base bg-white text-center"
            />
          )}
          <Column
            sortable
            field="createdAt"
            header="Created Dt"
            // sortField="createdAt"
            body={loading ? contentSkeleton() : renderCreatedAt}
            className="text-sm 2xl:text-base"
            headerClassName="text-sm 2xl:text-base bg-white"
          />
          {user && user["role"] !== "admin" && (
            <Column
              body={loading ? contentSkeleton() : options}
              className="text-sm 2xl:text-base"
              headerClassName="text-sm 2xl:text-base bg-white"
            />
          )}
        </DataTable>
      </div>
      {totalCount > 9 && (
        <CustomPaginator
          limit={limit}
          defaultRows={10}
          totalRecords={JDs}
          setLimit={setLimit}
          totalPages={totalPage}
          totalCount={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
}
