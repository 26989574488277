export const interviewEmailTemplate = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>Interview Invitation</title>
</head>
<body style="margin: 0; padding: 0; background-color: #cae5f4; font-size: .9rem; color: #2B4360;">
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
            <td>
                <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
                    <tr>
                        <td bgcolor="#ECF5FA" style="padding: 20px; border-radius: 10px;">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td>
                                        <img src="https://skillassessment.blob.core.windows.net/images/email-header.png" alt="Interview Invitation" width="100%" style="display: block;" />
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 20px 0 10px 0; color: #1a202c; font-size: 16px;">
                                        <h5 style="color: #1a202c; font-size: 1.0rem; font-weight: normal; padding: 0px; margin: 0px;">Dear candidateName,</h5>
                                        <p style="color: #1a202c; font-size: 1.0rem; padding: 12px 0 0 0; margin: 0px;">Thank you for your interest in exploring a role.</p>
                                        <p style="color: #1a202c; font-size: 1.0rem; padding: 12px 0 0 0; margin: 0px;">We are pleased to invite you to complete the PROCTORED interviewName with. <strong>Athmiya, an AI Interviewer.</strong></p>
                                        <p style="color: #1a202c; font-size: 1.0rem; padding: 12px 0 0 0; margin: 0px;">Please find the details below:</p>
                                        <ul style="margin: 5px;">
                                            <li style="color: #1a202c; font-size: 1.0rem;"><strong>Duration:</strong> interviewDuration minutes</li>
                                            <li style="color: #1a202c; font-size: 1.0rem;"><strong>Interview Link Expiry:</strong> expireAt</li>
                                            <li style="color: #1a202c; font-size: 1.0rem;"><strong>Supported Browser:</strong> Google Chrome (Version 130 or above)</li>
                                        </ul>
                                        <p style="color: #1a202c; font-size: 1.0rem; padding: 12px 0 0 0; margin: 0px;">You can start the interview by clicking the link below <br>
                                            <strong style="color: #3C99CA; font-size: 1.0rem; font-weight: 500;">
                                                <a style="color: #3C99CA; text-decoration: none;" href="interviewLink">Click here</a>
                                            </strong>
                                        </p>
                                        <p style="color: #1a202c; font-size: 1.0rem; padding: 8px 0 0 0; margin: 0px;">
                                            Attached the Job Description (JD) for your reference.
                                        </p>
                                        <p style="color: #1a202c; font-size: 1.0rem;">
                                            We’re excited to have you participate in this innovative and engaging recruitment
                                            experience. If you need any assistance, feel free to reach out to us at
                                            <strong style="color: #3C99CA; font-size: 1.0rem; font-weight: 500; text-decoration: none;">support@athmick.com</strong>
                                        </p>
                                        <p style="font-weight: 500; margin-top: 20px; color: #1a202c;">Best regards,<br /> <strong>Athmick Precision Recruiter</strong></p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#2B4360" style="padding: 15px 20px; color: white; border-radius: 0 0 10px 10px;">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td width="60%">
                                        <h3 style="font-size: 10px; font-weight: 500; margin: 0; letter-spacing: .03rem;">Powered by</h3>
                                        <img src="https://skillassessment.blob.core.windows.net/images/logo-2.png" alt="Athmick logo" width="100" style="display: block; margin-bottom: 5px;" />
                                        <p style="font-size: 10px; font-weight: 500; margin: 0; letter-spacing: .03rem; color: #ffffff;">All Rights Reserved, 2024</p>
                                    </td>
                                    <td width="40%" align="right" valign="top">
                                        <a href="https://athmick.ai" style="color: white; text-decoration: none; font-size: 12px; display: block; margin-bottom: 10px;">www.athmick.ai</a>
                                        <table border="0" cellpadding="0" cellspacing="0">
                                            <tr>
                                                <td>
                                                    <a href="https://www.facebook.com/people/Athmick/61558493352873/?mibextid=ZbWKwL">
                                                        <img src="https://skillassessment.blob.core.windows.net/images/facebook.png" alt="Facebook" width="20" height="20" style="display: block;" border="0" />
                                                    </a>
                                                </td>
                                                <td style="font-size: 0; line-height: 0;" width="10">&nbsp;</td>
                                                <td>
                                                    <a href="https://www.instagram.com/athmick/?igsh=MXZtNGM3aHlsaWs3eA%3D%3D">
                                                        <img src="https://skillassessment.blob.core.windows.net/images/instagram.png" alt="Instagram" width="20" height="20" style="display: block;" border="0" />
                                                    </a>
                                                </td>
                                                <td style="font-size: 0; line-height: 0;" width="10">&nbsp;</td>
                                                <td>
                                                    <a href="https://www.linkedin.com/company/athmick/">
                                                        <img src="https://skillassessment.blob.core.windows.net/images/linkedin.png" alt="LinkedIn" width="20" height="20" style="display: block;" border="0" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>
</html>`

export const followUpInterviewEmailTemplate = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>Follow up</title>
</head>
<body style="margin: 0; padding: 0; background-color: #cae5f4; font-size: .9rem; color: #2B4360;">
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
            <td>
                <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
                    <tr>
                        <td bgcolor="#ECF5FA" style="padding: 20px; border-radius: 10px;">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td>
                                        <img src="https://skillassessment.blob.core.windows.net/images/email-header.png" alt="Interview Invitation" width="100%" style="display: block;" />
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 20px 0 10px 0; color: #1a202c; font-size: 16px;">
                                        <h5 style="color: #1a202c; font-size: 1.0rem; font-weight: normal; padding: 0px; margin: 0px;">Dear candidateName</h5>
                                        <p style="color: #1a202c; font-size: 1.0rem; padding: 12px 0 0 0; margin: 0px;">This is a gentle reminder that your invitation to participate in a interviewName with. <strong>Athmiya, our AI Interviewer, </strong>is still pending.</p>
                                        <p style="color: #1a202c; font-size: 1.0rem; padding: 12px 0 0 0; margin: 0px;">To ensure your application progresses smoothly, please refer to the previous email and complete the interviewName before the link expires on <strong>expireAt</strong></p>
                                        <p style="color: #1a202c; font-size: 1.0rem; padding: 12px 0 0 0; margin: 0px;">We appreciate your time in engaging with this innovative process. If you have any questions or need assistance, don’t hesitate to contact at <strong style="color: #3C99CA; font-size: 1.0rem; font-weight: 500; text-decoration: none;">support@athmick.com</strong></p>
                                        <p style="color: #1a202c;">Wishing you the very best!</p>
                                        <p style="font-weight: 500; margin-top: 20px; color: #1a202c;">Best regards,<br /> <strong>Athmick Precision Recruiter</strong></p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#2B4360" style="padding: 15px 20px; color: white; border-radius: 0 0 10px 10px;">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td width="60%">
                                        <h3 style="font-size: 10px; font-weight: 500; margin: 0; letter-spacing: .03rem;">Powered by</h3>
                                        <img src="https://skillassessment.blob.core.windows.net/images/logo-2.png" alt="Athmick logo" width="100" style="display: block; margin-bottom: 5px;" />
                                        <p style="font-size: 10px; font-weight: 500; margin: 0; letter-spacing: .03rem; color: #ffffff;">All Rights Reserved, 2024</p>
                                    </td>
                                    <td width="40%" align="right" valign="top">
                                        <a href="https://athmick.ai" style="color: white; text-decoration: none; font-size: 12px; display: block; margin-bottom: 10px;">www.athmick.ai</a>
                                        <table border="0" cellpadding="0" cellspacing="0">
                                            <tr>
                                                <td>
                                                    <a href="https://www.facebook.com/people/Athmick/61558493352873/?mibextid=ZbWKwL">
                                                        <img src="https://skillassessment.blob.core.windows.net/images/facebook.png" alt="Facebook" width="20" height="20" style="display: block;" border="0" />
                                                    </a>
                                                </td>
                                                <td style="font-size: 0; line-height: 0;" width="10">&nbsp;</td>
                                                <td>
                                                    <a href="https://www.instagram.com/athmick/?igsh=MXZtNGM3aHlsaWs3eA%3D%3D">
                                                        <img src="https://skillassessment.blob.core.windows.net/images/instagram.png" alt="Instagram" width="20" height="20" style="display: block;" border="0" />
                                                    </a>
                                                </td>
                                                <td style="font-size: 0; line-height: 0;" width="10">&nbsp;</td>
                                                <td>
                                                    <a href="https://www.linkedin.com/company/athmick/">
                                                        <img src="https://skillassessment.blob.core.windows.net/images/linkedin.png" alt="LinkedIn" width="20" height="20" style="display: block;" border="0" />
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>
</html>`