// components/Layout.jsx
import React from 'react';
import Header from '../Landing/Header';
import Footer from '../Landing/Footer';
import { Outlet } from 'react-router-dom';
// import { trackPageView } from '../../utils/googleAnalytics';

const Layout = () => {
  // trackPageView(window.location.pathname);

  return (
    <div className="min-h-screen w-full flex flex-col">
      <Header />
      <main className='flex-1'>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;