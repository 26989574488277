import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { BounceLoader } from 'react-spinners';
import { Rating } from '@smastrom/react-rating';
import { RESUME_STATUS } from '../../utils/constants';
import ReportService from '../../services/report.service';
import { useToast } from '../../contextProviders/ToastContext';
import defaultImage from './../../assets/images/default-interview-image.png';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';
import { convertUTCToLocal, extractOverallAssessment, secondsToMinutes } from '../../utils/common';

export default function ReportInitialScreening({report, setShowInterviewFeedbackPopup}){
    const videoRef = useRef(null);
    const {showErrorMessage} = useToast();

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);

    const initialScreening = (report && report?.initial_screening) ? report?.initial_screening[0] : report ? report : {};
    const audioAnalysis = initialScreening?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_00')?.[0];
    const reportFeedback = initialScreening?.['report_feedback'] || initialScreening?.['reportFeedback'];
    const showAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(true);
    const hideAudioAnalysisReportPopup = () => setShowAudioAnalyisReport(false);

    const getDurationInSeconds = () => {
        if(initialScreening?.['start_time'] && initialScreening?.['end_time']){
            const startTime = new Date(initialScreening?.['start_time']);
            const endTime = new Date(initialScreening?.['end_time']);
            const durationMilliseconds = endTime - startTime;
            return durationMilliseconds / 1000;
        } 
        else if(initialScreening?.['startTime'] && initialScreening?.['endTime']){
            const startTime = new Date(initialScreening?.['startTime']);
            const endTime = new Date(initialScreening?.['endTime']);
            const durationMilliseconds = endTime - startTime;
            return durationMilliseconds / 1000;
        } 
        return 'NA';
    }

    const getValue = (value) => {
        if(!value) return;
        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value ? value?.toString()?.toLowerCase() : null;
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const extractReport = async () => {
        try {
            setLoading(true);
            const response = await ReportService.getReport(report['id'] || initialScreening['id']);
            setLoading(false);
            if(response.status === 200){
                const blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");

                const fileName = report['candidate'] ? `${report['candidate']['Name']}-${Date.now()}.pdf` : 
                    report['candidate_name'] ? `${report['candidate_name']}-${Date.now()}.pdf` : `${Date.now()}.pdf`

                link.href = URL.createObjectURL(blob);
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            showErrorMessage({ summary: 'Failed', detail: 'Failed to extract report'})
            setLoading(false);
        }
    }

    if(!initialScreening || (typeof initialScreening == 'object' &&  Object.keys(initialScreening).length === 0)){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    report?.status['initialScreening'] === RESUME_STATUS.COMPLETED ? 
                        'Report is being prepared, will be available shortly.' : 'Report not initiated'
                    } 
                </h1>
            </div>
        )
    }

    return (
        <div className='flex flex-col gap-5'>
            {showAudioAnalyisReport && (
                    <AudioAnalysisReportPopup 
                        audioAnalysis={audioAnalysis} 
                        close={hideAudioAnalysisReportPopup} 
                    />
                )
            }
            {showTranscript && (
                <TranscriptComponent  
                    hide={() => setShowTranscript(false)}
                    id={initialScreening?.id || initialScreening?._id}
                />
            )}

            <div className='flex justify-between items-center'>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-row justify-between flex-1 h-full gap-3'>
                        {initialScreening?.['video_path'] && (
                            <ReactPlayer
                                ref={videoRef}
                                controls
                                pip={false}
                                playing={isPlaying}
                                onPlay={handlePlay}
                                onPause={handlePause}
                                className="react-player flex-1"
                                url={initialScreening?.video_path}
                            />
                        )}
                        {!initialScreening?.['video_path'] && (
                            <div  className="react-player flex-1 flex-col-reverse flex gap-4 relative">
                                <img 
                                    alt='default'
                                    src={defaultImage}
                                    className='h-full rounded-md'
                                />
                                <div className='absolute bottom-20 z-50 px-3 text-center flex justify-center items-center w-full'>
                                    <p className='text-white font-bold text-center text-base'>Video of the interview is not available</p>
                                </div>
                            </div>
                        )}
                        <div className='flex flex-col gap-4 flex-1'>
                            <div className={`flex items-center gap-2 text-primary mt-5 ${initialScreening?.start_time ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Date of the interview :</h3>
                                <span className=''>{initialScreening?.start_time ? convertUTCToLocal(initialScreening?.start_time) : 'Not captured' }</span>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening['is_interested'] ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Is interested in role:</h3>
                                <h4 className=''>{initialScreening['is_interested'] ? 'Yes' : 'No'}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.notice_period ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Notice Period:</h3>
                                <h4 className=''>{initialScreening?.notice_period}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.current_ctc ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Current CTC:</h3>
                                <h4 className=''>{initialScreening?.current_ctc}</h4>
                            </div>
                            <div className={`flex items-center gap-2 text-primary ${initialScreening?.expected_ctc ? 'visible': 'hidden'}`}>
                                <h3 className='font-semibold'>Expected CTC:</h3>
                                <h4 className=''>{initialScreening?.expected_ctc}</h4>
                            </div>
                            <div className='flex items-center gap-2 text-primary'>
                                <h3 className='font-semibold'>Interview Status:</h3>
                                <span className=''>{initialScreening?.status}</span>
                            </div>
                            <div className='flex items-center gap-2 text-primary'>
                                <h3 className='font-semibold'>Interview Duration:</h3>
                                <span className=''>{secondsToMinutes(getDurationInSeconds())}</span>
                            </div>
                            {/* <div className='flex items-center gap-2'>
                                <p className='font-semibold text-primary'>Transcript of the interview</p>
                                <CgTranscript 
                                    size={28}
                                    className="cursor-pointer"
                                    onClick={() => setShowTranscript(!showTranscript)}
                                />
                            </div> */}
                            {/* <button
                                disabled={loading}
                                onClick={extractReport} 
                                className='border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                                {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex items-center gap-5'>
                <button
                    onClick={() => setShowTranscript(!showTranscript)}
                    className='border text-xs h-9 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary'>
                    View Transcript
                </button>
                <button
                    disabled={loading}
                    onClick={extractReport} 
                    className={`border h-9 w-36 px-6 flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${report?.total_score > 0 ? 'visible': 'hidden'}`}>
                    {loading ? <BounceLoader size={26} color="#2B4360" /> : <span className="text-xs">Extract Report</span>}
                </button>
                <button
                    onClick={() => setShowInterviewFeedbackPopup(true)} 
                    className={`border h-9 px-6 text-xs flex justify-center items-center hover:bg-primary hover:text-white font-medium rounded-md border-primary ${!reportFeedback?.status ? 'visible': 'hidden'}`}>
                    Candidate Selection
                </button>
            </div>
            <div className={`${initialScreening?.roles_and_responsibilities?.length > 0 ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Roles And Responsibilities</h4>
                <ul className='flex gap-5 flex-wrap my-5'>
                    {initialScreening?.roles_and_responsibilities?.map((item, index) => (
                        <li key={index?.toString()} className='bg-lightBlue p-3 rounded-lg text-primary'>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={`flex flex-col gap-3 ${initialScreening?.summary ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Summary</h4>
                <CustomReactMarkdown data={initialScreening?.summary} />
            </div>
            <div className={`flex flex-col gap-3 pb-5 mt-3 ${initialScreening?.relevance ? 'visible': 'hidden'}`}>
                <h4 className='text-xl font-bold'>Relevance</h4>
                <CustomReactMarkdown data={initialScreening?.relevance} />
            </div>
            <div className={`flex flex-col ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-2`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className={`flex mt-2 flex-col gap-3 ${audioAnalysis?.score !== undefined ? 'visible': 'hidden' }`}>
                        {/* <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRatingForBpoFlow(audioAnalysis?.score, user?.['organization']).value} 
                        />
                        <h2 className={`text-sm font-semibold text-primary`}>
                            {getRatingForBpoFlow(audioAnalysis?.score, user?.['organization']).title}
                        </h2> */}
                        {extractOverallAssessment(audioAnalysis?.['summary']) && 
                            <CustomReactMarkdown data={extractOverallAssessment(audioAnalysis?.['summary'])}/>
                        }
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`flex flex-col gap-3 ${reportFeedback && reportFeedback?.status ? 'visible': 'hidden'}`}>
                <div className='flex gap-4 items-center'>
                    <h1 className='text-xl font-bold'>Recruiter feedback on next round</h1>
                    <div className={`text-sm h-7 px-3 rounded-full flex justify-center items-center font-semibold ${reportFeedback?.status === 'SELECTED' ? 'bg-dg': reportFeedback?.status === 'REJECTED' ? 'bg-dr': 'bg-do'}`}>
                        <span className={`text-xs ${reportFeedback?.status === 'SELECTED' ? 'text-lgg': reportFeedback?.status === 'REJECTED' ? 'text-lr': 'text-lo'}`}>{reportFeedback?.status}</span>
                    </div>
                </div>
                {reportFeedback?.feedback && <p className='text-primary'>{reportFeedback?.feedback}</p>}
            </div>
            <div className={`flex flex-col gap-3 ${initialScreening['candidate_feedback'] && initialScreening['candidate_feedback']?.rating >= 1 ? 'visible': 'hidden'}`}>
                <div className='flex gap-5'>
                    <h1 className='text-xl font-bold'>Candidate Feedback</h1>
                    <Rating 
                        readOnly
                        color='#B99000' 
                        cancel={false} 
                        style={{width: 120}}
                        value={initialScreening['candidate_feedback']?.rating} 
                    />
                </div>
                <p className='text-primary'>{initialScreening['candidate_feedback']?.message}</p>
            </div>
        </div>
    )
}