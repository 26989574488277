import axios from "axios";
import axiosRetry from 'axios-retry';
import uploadAxios from "axios";
import axiosInstance from ".";
import CommonService from "./common.service";
import { RESUME_STAGES } from "../utils/constants";
import { getDateRange } from "../utils/common";

axiosRetry(uploadAxios, { retries: 5, retryDelay: (retryCount) => {
    return retryCount * 1000;
}});

async function recordedFileUpload(props){
    const {fileName, sessionId, file, token} = props;
    const presignUrl = await CommonService.generatePresignUrlForSessionFileUpload(sessionId, fileName, token);
    const {url} = presignUrl.data.data;
    await uploadAxios.put(url, file, {
        headers: {'x-ms-blob-type': 'BlockBlob'}
    });
}

function getTranscript(sessionId){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'))
    return axios.get(`${process.env.REACT_APP_API_URL}/screening/transcribe/${sessionId}`, {
        headers: { 'Authorization': `Bearer ${accessToken.token}`}
    })
}

function verifySessionKey(key){
    return axiosInstance.post('/verifySessionKey', {key })
}

function submitInterviewFeedback(sessionId, reportFeedback){
    return axiosInstance.post(`/sessions/${sessionId}/feedback`, reportFeedback)
}

function getSessions(page, limit, interviewType, status, search, dateRange){
    if(search)
        return axiosInstance.get(`/sessions?dateRange=${getDateRange(dateRange)}&page=${page}&limit=${limit}&status=${status}&search=${search}`)
    if(interviewType !== RESUME_STAGES.RESUME_SCREENING)    
        return axiosInstance.get(`/sessions?dateRange=${getDateRange(dateRange)}&page=${page}&status=${status}&limit=${limit}&interviewType=${interviewType}`)
    return axiosInstance.get(`/sessions?dateRange=${getDateRange(dateRange)}&page=${page}&status=${status}&limit=${limit}`)
}

function getSession(sessionId, interviewType){
    return axiosInstance.get(`/sessions/${sessionId}?interviewType=${interviewType}`)
}

function extractRecords(dateRange = '1year', interviewType){
    if(interviewType)
        return axiosInstance.get(`/sessions/extractRecords?dateRange=${dateRange}&interviewType=${interviewType}`, {responseType: 'blob'});
    return axiosInstance.get(`/sessions/extractRecords?dateRange=${dateRange}`, {responseType: 'blob'});
}



const SessionService = {
    getSession,
    getSessions,
    getTranscript,
    extractRecords,
    verifySessionKey,
    recordedFileUpload,
    submitInterviewFeedback
}

export default SessionService;