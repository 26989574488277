import React from "react";
import { MdClear } from "react-icons/md";

export default function ShortlistAllResumePopup({ hide, sortlistAllResume }) {
    const handleSubmit = () => {
        try {
            sortlistAllResume();
            hide();
        } catch (error) {
            console.error("Failed to shortlist resumes:", error);
        }
    };

    return (
        <div className="fixed inset-0 z-40 flex items-center justify-center p-4 bg-black bg-opacity-50">
            <div className="w-full max-w-md bg-white rounded-lg shadow overflow-hidden">
                <div className="p-6 flex flex-col gap-5">
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-base font-semibold text-darkGray">
                            Shortlist all resumes
                        </h1>
                        <MdClear
                            size={20}
                            className="text-brownGray cursor-pointer"
                            onClick={hide}
                        />
                    </div>
                    <p className="text-gray-700">
                        Are you sure you want to shortlist all resumes?
                    </p>
                    <div className="flex justify-end space-x-4">
                        <button
                            className="px-4 py-2 text-blue text-sm border-blue border-[1px] rounded-md"
                            onClick={hide}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-4 py-2 bg-blue text-sm rounded-md text-white"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
