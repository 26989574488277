import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { Tooltip } from 'primereact/tooltip';
import { CgFileDocument } from "react-icons/cg";
import { DataTable } from "primereact/datatable";
import ResumeService from "../../services/resume.service";
import ResumeViewerPopup from "../../components/JD/ResumeViewerPopup";
import { capitalizeFirstLetterOfEachWord } from "../../utils/common";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { contentSkeleton } from "../../components/Common/TableLoadingComponents";
import LoadingComponent from "../../components/Common/LoadingComponent";

export default function SearchPage(){

    const [searchText, setSearchText] = useState(null);
    const [resumeUrl, setResumeUrl] = useState(0);
    const [loading, setLoading] = useState(false);
    const [resumes, setResumes] = useState([]);
    const [showResumePopup, setShowResumePopup] = useState(false);
    const navigate = useNavigate();
    const getResumes = async () => {
        try {
            setLoading(true);
            const response = await ResumeService.searchResumes(searchText);
            setLoading(false);
            if(response.status === 200){
                const {data} = response.data;
                setResumes(data);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const showResume = (item) => {
        if(item['resumeUrl'])
            setResumeUrl(item['resumeUrl']);
        setShowResumePopup(true);
    }
    

    const viewResume = (item) => {
        return (
            <div 
                onClick={(event) => event?.stopPropagation()}
                className="flex gap-4 items-center w-10">
                <CgFileDocument 
                    size={20}
                    onClick={showResume.bind(this, item)}
                    className='cursor-pointer text-primary'
                />
            </div>
        )
    }


    const renderEmail = ({meta, _id}) => {
        const dynamicClass = `tooltip-email-${_id}`;
        const email = meta?.['Email'] || meta?.['email'];

        if(email){
            return (
                <div className="flex gap-4 items-center w-44">
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <h3 className='text-xs'>{email}</h3>
                            <span className='text-xs text-blue'>Click on email to copy</span>
                        </div>
                    </Tooltip>
                    <span className={`text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer ${dynamicClass}`}>{email}</span>
                </div>
            )
        }

        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    -
                </span>
            </div>
        )
    }

    const renderName = ({meta, _id}) => {

        const dynamicClass = `tooltip-name-${_id}`;
        const name = meta?.['Name'] || meta?.['name'];

        if(name){
            return (
                <div className="flex gap-4 items-center w-32">
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <h3 className='text-xs'>{capitalizeFirstLetterOfEachWord(name)}</h3>
                        </div>
                    </Tooltip>
                    <span className={`text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer ${dynamicClass}`}>
                        {capitalizeFirstLetterOfEachWord(name)}
                    </span>
                </div>
            )
        }
        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>-</span>
            </div>
        )
    }

    return (
        <div className="w-full h-screen flex overflow-hidden">
            <div className="bg-[#ECF5FA] flex-col min-h-full max-sm:!w-full w-full">
                {showResumePopup && (
                    <ResumeViewerPopup  
                        header="Resume"
                        resumeUrl={resumeUrl} 
                        close={() => setShowResumePopup(false)} 
                    />
                )}
                <div className="px-10 pt-5 gap-5 h-full flex flex-col">
                    <div className="flex gap-5">
                        <InputText
                            value={searchText}
                            placeholder="Search resumes by email or name"
                            onChange={(event) => setSearchText(event.target.value)}
                            className="border border-[#EAECF0] h-8 px-2 w-1/3 text-sm font-medium text-primary"
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && searchText?.trim()?.length > 0) {
                                    getResumes();
                                }
                            }}
                        />
                        <button
                            onClick={() => getResumes()} 
                            disabled={!searchText || searchText?.trim()?.length === 0}
                            className={`bg-blue text-white h-8 px-4 rounded-md text-sm font-semibold ${!searchText || searchText?.trim()?.length === 0 ? 'opacity-50': 'opacity-100'}`}>
                            Search
                        </button>
                    </div>
                    <div className="h-full overflow-hidden">
                        <DataTable
                            rows={200}
                            dataKey="_id"
                            editMode="row" 
                            value={resumes}
                            selectionMode='checkbox'
                            emptyMessage= {loading ? LoadingComponent : "No resume available"}
                            className="text-brownGray overflow-y-scroll h-full"
                            rowClassName='bg-white hover:text-blue hover:cursor-pointer'
                            onRowClick={({data}) => {
                                navigate(`/dashboard/reports/${data?.['_id']}`, { state: { resumeUrl : data?.resumeUrl }});
                            }}>
                            <Column 
                                body={loading ? contentSkeleton : viewResume}  
                                className='w-10 p-0 pl-3'
                                headerClassName='text-sm' 
                            />
                            <Column  
                                header="Jd Title" 
                                field={loading ? contentSkeleton : 'jdDetails.title'}
                                className='text-sm w-1/10'
                                headerClassName='text-sm bg-white' 
                            />
                            <Column  
                                header="Hr Email" 
                                field={loading ? contentSkeleton : 'user.email'}
                                className='text-sm w-1/10'
                                headerClassName='text-sm bg-white' 
                            />
                            <Column  
                                header="Hr Name" 
                                field= {loading ? contentSkeleton : 'user.firstName'}
                                className='text-sm w-1/10'
                                headerClassName='text-sm bg-white' 
                            />
                            <Column 
                                header="Candidate Name" 
                                field="name" 
                                body={loading ? contentSkeleton : renderName}
                                className='text-sm w-1/10'
                                headerClassName='text-sm bg-white'
                            />
                            <Column 
                                header="Email" 
                                body={loading ? contentSkeleton : renderEmail}
                                field="Candidate Email"
                                className='text-sm 2xl:text-base w-1/4'
                                headerClassName='text-sm bg-white'
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}